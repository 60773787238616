import { Table } from 'ui/molecules'

export const StopwordsTable = ({ stopwords, onDelete, onEdit }) => (
  <Table variant="responsive">
    <Table.Head className="whitespace-normal">
      <tr>
        <Table.HeadCell>№</Table.HeadCell>
        <Table.HeadCell>Стопслово</Table.HeadCell>
        <Table.HeadCell>Активно</Table.HeadCell>
        <Table.HeadCell>Действие</Table.HeadCell>
      </tr>
    </Table.Head>

    <Table.Body>
      {stopwords.map((word, idx) => (
        <Table.Row key={word.id}>
          <Table.Cell className="is-hidden-mobile">{idx + 1}</Table.Cell>
          <Table.Cell className="text-sm lg:text-xs">{word.name}</Table.Cell>
          <Table.Cell className="has-title" data-title="Статус:">
            {word.active ? 'Активно' : 'Неактивно'}
          </Table.Cell>
          <Table.Cell>
            <div className="flex items-center justify-center">
              <button
                className="grow md:grow-0 bg-green-500 text-white mr-2 py-2 px-4 rounded-md font-semibold"
                onClick={onEdit.bind(null, word)}
              >
                Изменить
              </button>
              <button
                className="grow md:grow-0 bg-red-100 text-red-500 py-2 px-4 rounded-md font-semibold"
                onClick={onDelete.bind(null, word)}
              >
                Удалить
              </button>
            </div>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)
