import { useDispatch, useSelector } from 'react-redux'

import { fetchSourceRegions } from 'redux/slices/sourceRegionsSlice'
import {
  getSourceRegionsData,
  getSourceRegionsError,
  getSourceRegionsLoadingState,
} from 'redux/selectors/sourceRegionsSelectors'

export const useSourceRegionsManager = () => {
  const sourceRegions = useSelector(getSourceRegionsData)
  const error = useSelector(getSourceRegionsError)
  const isLoading = useSelector(getSourceRegionsLoadingState)
  const dispatch = useDispatch()

  const loadSourceRegions = () => dispatch(fetchSourceRegions())

  return { sourceRegions, loadSourceRegions, error, isLoading }
}
