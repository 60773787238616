import cn from 'classnames'

const SelectComponents = {
  DropdownIndicator: null,
}

const customStyles = {
  container: provided => ({
    ...provided,
    // minWidth: '240px',
    // maxWidth: '240px',
  }),
  menuList: provided => ({
    ...provided,
    textAlign: 'left',
  }),
}

const options = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
]

export const NewsLengthSelect = ({ defaultValue, className, onChange }) => {
  return (
    <div className={cn('inline-flex items-center text-sm', className)}>
      <p className="mr-4">Результатов на страницу:</p>

      <select
        defaultValue={Number(defaultValue)}
        onChange={e => onChange(e.target.value)}
      >
        {options.map(option => (
          <option key={option.label} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}
