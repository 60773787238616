import React from 'react'
import cn from 'classnames'

export const Radio = React.forwardRef(
  ({ title, reversed = false, ...props }, ref) => (
    <label
      className={cn('flex items-center', {
        'flex-row-reverse': reversed,
      })}
    >
      <input className="mr-2" type="radio" name="name" ref={ref} {...props} />
      <p className="grow">{title}</p>
    </label>
  )
)
