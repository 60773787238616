import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

import { RoleBasedRedirect } from 'ui/molecules'
import { NotFoundPage } from 'ui/pages'

import { PATHS } from 'routing/paths'
import { selectAuthStatus, selectUserRole } from 'redux/selectors/authSelectors'

export const ProtectedRoutes = ({ allowedRoles }) => {
  const isAuthenticated = useSelector(selectAuthStatus)
  const role = useSelector(selectUserRole)

  const userHasRequiredRole = role && allowedRoles.includes(role)

  if (isAuthenticated && userHasRequiredRole) {
    return <Outlet />
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return <Navigate to="404" />
  }

  return <Navigate to={PATHS.HOME} replace />
}
