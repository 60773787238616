import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { UsersEditForm } from 'ui/organisms'
import { LayoutBase } from 'ui/layouts'

import { PATHS } from 'routing/paths'
import { editUser } from 'redux/slices/usersSlice'

export const UsersEditPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    state: { user },
  } = useLocation()

  const onUsersEditFormSubmit = async data => {
    try {
      const response = await dispatch(editUser(data)).unwrap()

      navigate(PATHS.USERS)
    } catch (error) {
      alert(error)
    }
  }

  const onUsersEditFormCancel = () => navigate(PATHS.USERS)

  return (
    <LayoutBase>
      <div className="container mx-auto pt-6 pb-12 px-4">
        <h1 className="mb-6 text-[32px] font-semibold">
          Редактировать пользователя
        </h1>

        <UsersEditForm
          defaultValues={user}
          onCancel={onUsersEditFormCancel}
          onSubmit={onUsersEditFormSubmit}
        />
      </div>
    </LayoutBase>
  )
}
