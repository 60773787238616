import { useLocation, useNavigate } from 'react-router-dom'

import { LayoutBase } from 'ui/layouts'
import { StopwordsForm } from 'ui/organisms'

import { PATHS } from 'routing/paths'
import { useStopwordsManager } from 'hooks/useStopwordsManager'

export const StopwordsEditPage = () => {
  const { editStopword } = useStopwordsManager()
  const navigate = useNavigate()
  const {
    state: { word },
  } = useLocation()

  const onSourcesFormSubmit = async data => {
    try {
      const response = await editStopword(data).unwrap()

      navigate(PATHS.STOPWORDS)
    } catch (error) {
      alert(error)
    }
  }

  const onSourcesFormCancel = () => navigate(PATHS.STOPWORDS)

  return (
    <LayoutBase>
      <div className="container  mx-auto pt-6 pb-12 px-4">
        <h1 className="mb-6 text-[32px] font-semibold">
          Редактировать стопслово
        </h1>

        <StopwordsForm
          defaultValues={word}
          onSubmit={onSourcesFormSubmit}
          onCancel={onSourcesFormCancel}
        />
      </div>
    </LayoutBase>
  )
}
