import { useEffect } from 'react'
import { Table } from 'ui/molecules'
import { getDateTimeFromTimestamp } from 'utils/date'
import { getSourceRegionById } from 'utils/sources'

import { useSourceRegionsManager } from 'hooks/useSourceRegionsManager'

export const SourcesTable = ({ sources, onDelete, onEdit }) => {
  const { sourceRegions, loadSourceRegions } = useSourceRegionsManager()

  useEffect(() => {
    loadSourceRegions()
  }, [])

  return (
    <Table variant="responsive">
      <Table.Head className="whitespace-normal">
        <tr>
          <Table.HeadCell>№</Table.HeadCell>
          <Table.HeadCell>Имя источника</Table.HeadCell>
          <Table.HeadCell>Ссылка на источник</Table.HeadCell>
          <Table.HeadCell>Регион</Table.HeadCell>
          <Table.HeadCell>Автопубликация</Table.HeadCell>
          <Table.HeadCell>Статус</Table.HeadCell>
          <Table.HeadCell>Последнее сканирование</Table.HeadCell>
          <Table.HeadCell>Кол-во за 24ч.</Table.HeadCell>
          <Table.HeadCell>Дельта</Table.HeadCell>
          <Table.HeadCell>Действие</Table.HeadCell>
        </tr>
      </Table.Head>
      <Table.Body>
        {sources.map((source, idx) => (
          <Table.Row key={source.id}>
            <Table.Cell className="is-hidden-mobile">{idx + 1}</Table.Cell>
            <Table.Cell className="text-sm lg:text-xs">
              {source.name}
            </Table.Cell>
            <Table.Cell className="text-sm lg:text-xs">{source.url}</Table.Cell>
            <Table.Cell className="text-sm lg:text-xs">
              {source.region
                ? getSourceRegionById(sourceRegions, source.region)
                : '-'}
            </Table.Cell>
            <Table.Cell className="has-title" data-title="Автопубликация:">
              {source.autopub ? 'Да' : 'Нет'}
            </Table.Cell>
            <Table.Cell className="has-title" data-title="Статус">
              {source.active ? 'Активен' : 'Неактивен'}
            </Table.Cell>
            <Table.Cell
              className="has-title"
              data-title="Дата последнего сканирования"
            >
              {source.last_grab_datetime
                ? getDateTimeFromTimestamp(source.last_grab_datetime)
                : '-'}
            </Table.Cell>
            <Table.Cell
              className="has-title"
              data-title="Новостей за последние 24ч.:"
            >
              {source.last_24h_news_number || '-'}
            </Table.Cell>
            <Table.Cell className="has-title" data-title="Дельта:">
              {source.last_24h_pub_delta || '-'}
            </Table.Cell>
            <Table.Cell>
              <div className="flex items-center justify-center">
                <button
                  className="grow md:grow-0 bg-green-500 text-white mr-2 py-2 px-4 rounded-md font-semibold"
                  onClick={onEdit.bind(null, source)}
                >
                  Изменить
                </button>
                <button
                  className="grow md:grow-0 bg-red-100 text-red-500 py-2 px-4 rounded-md font-semibold"
                  onClick={onDelete.bind(null, source)}
                >
                  Удалить
                </button>
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}
