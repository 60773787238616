import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { BASE_ERROR_MESSAGE } from 'constants/messages'
import api from 'api/api'

export const fetchSourceRegions = createAsyncThunk(
  'sourceRegions/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.sources.fetchRegions()

      return response
        ? response.data.regions
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(error || BASE_ERROR_MESSAGE)
    }
  }
)

const initialState = {
  data: [],
  loading: false,
  error: null,
}

export const sourceRegionsSlice = createSlice({
  name: 'sourceRegions',
  initialState,
  extraReducers: {
    [fetchSourceRegions.pending]: state => {
      state.loading = true
    },
    [fetchSourceRegions.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = false
      state.error = null
    },
    [fetchSourceRegions.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})

export default sourceRegionsSlice.reducer
