import { useNavigate } from 'react-router-dom'

import { LayoutBase } from 'ui/layouts'
import { StopwordsForm } from 'ui/organisms'

import { PATHS } from 'routing/paths'
import { useStopwordsManager } from 'hooks/useStopwordsManager'

export const StopwordsAddPage = () => {
  const navigate = useNavigate()
  const { addStopword } = useStopwordsManager()

  const onStopwordsFormSubmit = async data => {
    try {
      const response = await addStopword(data).unwrap()

      navigate(PATHS.STOPWORDS)
    } catch (error) {
      alert(error)
    }
  }

  const onStopwordsFormCancel = () => navigate(PATHS.STOPWORDS)

  return (
    <LayoutBase>
      <div className="container  mx-auto pt-6 pb-12 px-4">
        <h1 className="mb-6 text-[32px] font-semibold">Добавить стопслово</h1>

        <StopwordsForm
          onSubmit={onStopwordsFormSubmit}
          onCancel={onStopwordsFormCancel}
        />
      </div>
    </LayoutBase>
  )
}
