import { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import NumericInput from 'react-numeric-input'

export const StatDeepFilter = ({ onChange }) => {
  const [value, setValue] = useState(1)

  const handleChange = value => {
    const result = !value || value <= 0 ? 1 : value

    setValue(result)
    onChange(result)
  }

  const debouncedChangeHandler = useDebouncedCallback(handleChange, 300)

  return (
    <NumericInput
      className="w-[240px] h-[38px] border rounded border-[#cccccc]"
      min={1}
      max={100}
      value={value}
      onChange={debouncedChangeHandler}
    />
  )
}
