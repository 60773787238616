import { Table } from 'ui/molecules'
import { getDateTimeFromTimestamp } from 'utils/date'

export const StatisticsTable = ({ statistics }) => (
  <Table variant="responsive">
    <Table.Head className="whitespace-normal">
      <tr>
        <Table.HeadCell>№</Table.HeadCell>
        <Table.HeadCell>Дата</Table.HeadCell>
        <Table.HeadCell>Кол-во новостей</Table.HeadCell>
        <Table.HeadCell>
          Попыток парсинга <br /> (успешных, не успешных, всего)
        </Table.HeadCell>
      </tr>
    </Table.Head>

    <Table.Body>
      {statistics.map((item, idx) => (
        <Table.Row key={idx}>
          <Table.Cell className="is-hidden-mobile">{idx + 1}</Table.Cell>
          <Table.Cell
            className="text-sm lg:text-xs has-title"
            data-title="Дата:"
          >
            {getDateTimeFromTimestamp(item.date * 1000)}
          </Table.Cell>
          <Table.Cell
            className="text-sm lg:text-xs has-title"
            data-title="Кол-во новостей:"
          >
            {item.value}
          </Table.Cell>
          <Table.Cell
            className="text-sm lg:text-xs has-title"
            data-title="Попыток парсинга (успешных, не успешных, всего):"
          >
            {item.grub_success_number}\{item.grub_failure_number}\
            {item.grab_number}
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)
