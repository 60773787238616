import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import * as R from 'ramda'

import {
  selectSourcesData,
  selectSourcesLoadingState,
} from 'redux/selectors/sourcesSelectors'
import { fetchSources } from 'redux/slices/sourcesSlice'
import { getSourcesById, getSourcesByIdNew } from 'utils/sources'

const SelectComponents = {
  DropdownIndicator: null,
}

const customStyles = {
  container: provided => ({
    ...provided,
    minWidth: '240px',
    maxWidth: '240px',
  }),
  menuList: provided => ({
    ...provided,
    textAlign: 'left',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#000',
  }),
}

export const SourcesSelect = ({ defaultValues, onChange }) => {
  const [selected, setSelected] = useState([])
  const sources = useSelector(selectSourcesData)
  const isLoading = useSelector(selectSourcesLoadingState)
  const dispatch = useDispatch()

  // console.log(defaultValues)

  useEffect(() => {
    dispatch(fetchSources())
  }, [])

  useEffect(() => {
    if (defaultValues) {
      setSelected(defaultValues)
    }
  }, [defaultValues])

  useEffect(() => {
    onChange(selected)
  }, [selected])

  const onSelectChange = e => {
    setSelected(e && e.length > 0 ? e.map(x => x.id) : [])
  }

  if (isLoading) {
    return <p>Загрузка...</p>
  }

  return (
    <Select
      // defaultValue={getSourcesById(sources, defaultValues)}
      components={SelectComponents}
      styles={customStyles}
      options={sources}
      value={sources.filter(x => selected.includes(x.id))}
      placeholder="Выбрать источник"
      isMulti
      isClearable={true}
      isSearchable={false}
      onChange={onSelectChange}
      getOptionLabel={R.prop('name')}
      getOptionValue={R.prop('id')}
    />
  )
}
