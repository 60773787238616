import dayjs from 'dayjs'

export const getDateFromTimestamp = timestamp =>
  dayjs(timestamp).format('DD.MM.YY')

export const getTimeFromTimestamp = timestamp =>
  dayjs(timestamp).format('HH:mm')

export const getDateTimeFromTimestamp = timestamp =>
  dayjs(timestamp).format('DD.MM.YY HH:mm')
