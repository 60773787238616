import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { LayoutBase } from 'ui/layouts'
import { StopwordsTable } from 'ui/organisms'

import { PATHS } from 'routing/paths'
import { useStopwordsManager } from 'hooks/useStopwordsManager'

const StopwordsNoData = () => <p className="text-center">Стопслов нет.</p>

const StopwrodsError = () => (
  <p className="text-center">Произошла ошибка. Попробуйте позже.</p>
)

const StopwordsLoading = () => <p className="text-center">Загрузка...</p>

export const StopwordsPage = () => {
  const { stopwords, error, isLoading, loadStopwords, deleteStopword } =
    useStopwordsManager()
  const navigate = useNavigate()

  useEffect(() => {
    loadStopwords()
  }, [])

  const onStopwordEdit = word =>
    navigate(PATHS.STOPWORDS_EDIT, { state: { word } })

  const onStopwordDelete = word => {
    let isConfirmed = window.confirm(
      `Вы уверены, что хотите удалить стопслово ${word.name}?`
    )

    if (isConfirmed) {
      deleteStopword(word.id)
    }

    return null
  }

  return (
    <LayoutBase>
      <div className="container  mx-auto pt-6 pb-12 px-4 bg-neutral-100 lg:bg-transparent">
        <header className="mb-10">
          <h1 className="mb-6 text-[32px] font-semibold">Стопслова</h1>
          <Link
            to={PATHS.STOPWORDS_ADD}
            className="bg-green-500 text-white py-2 px-4 rounded-md font-semibold"
          >
            Добавить стопслово
          </Link>
        </header>

        {isLoading && <StopwordsLoading />}
        {!isLoading && !stopwords.length && <StopwordsNoData />}
        {!isLoading && error && <StopwrodsError />}
        {!isLoading && stopwords.length && (
          <StopwordsTable
            stopwords={stopwords}
            onEdit={onStopwordEdit}
            onDelete={onStopwordDelete}
          />
        )}
      </div>
    </LayoutBase>
  )
}
