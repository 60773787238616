import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { BASE_ERROR_MESSAGE } from 'constants/messages'
import api from 'api/api'

export const fetchWidgets = createAsyncThunk(
  'wigets/fetch',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.widgets.fetch()

      return response ? response.data : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const saveWidgets = createAsyncThunk(
  'widgets/save',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.widgets.edit(payload)

      return response && response.status === 204
        ? payload
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

const initialState = {
  data: {},
  loading: false,
  error: false,
}

export const widgetSlice = createSlice({
  name: 'widgets',
  initialState,
  extraReducers: {
    [fetchWidgets.pending]: state => {
      state.loading = true
    },
    [fetchWidgets.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = false
      state.error = false
    },
    [fetchWidgets.error]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [saveWidgets.pending]: state => {
      state.loading = true
    },
    [saveWidgets.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = false
      state.error = false
    },
    [saveWidgets.error]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})

export default widgetSlice.reducer
