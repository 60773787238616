import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'

import { ProtectedRoutes, PublicRoutes } from 'ui/molecules'
import {
  AutopubPage,
  Banners,
  BannerWidget,
  BannerWidgetAdd,
  BannerWidgetEdit,
  BannerTop,
  BannerTopAdd,
  BannerTopEdit,
  CategoriesPage,
  CategoriesAddPage,
  CategoriesEditPage,
  FeedPage,
  LoginPage,
  NewsPage,
  NotFoundPage,
  SourcesPage,
  SourcesAddPage,
  SourcesEditPage,
  StatisticsPage,
  StopwordsPage,
  StopwordsAddPage,
  StopwordsEditPage,
  UsersPage,
  UsersAddPage,
  UsersEditPage,
  WidgetsPage,
} from 'ui/pages'

import { PATHS } from 'routing/paths'
import { ROLES } from 'constants/roles'

const App = () => (
  <Router>
    <Routes>
      <Route path="*" element={<NotFoundPage />} />

      {/* PUBLIC ROUTES */}
      <Route element={<PublicRoutes />}>
        <Route path={PATHS.HOME} element={<LoginPage />} />
      </Route>

      {/* MODERATOR ROUTES */}
      <Route element={<ProtectedRoutes allowedRoles={[ROLES.MODERATOR]} />}>
        <Route path={PATHS.AUTOPUB} element={<AutopubPage />} />
        <Route path={`${PATHS.BANNERS}/*`} element={<Banners />}>
          <Route index element={<Navigate to="widget" />} />
          <Route path="widget" element={<BannerWidget />} />
          <Route path="widget/add" element={<BannerWidgetAdd />} />
          <Route path="widget/edit" element={<BannerWidgetEdit />} />
          {/* <Route path="top" element={<BannerTop />} />
          <Route path="top/add" element={<BannerTopAdd />} />
          <Route path="top/edit" element={<BannerTopEdit />} /> */}
        </Route>
        <Route path={PATHS.CATEGORIES} element={<CategoriesPage />} />
        <Route path={PATHS.CATEGORIES_ADD} element={<CategoriesAddPage />} />
        <Route path={PATHS.CATEGORIES_EDIT} element={<CategoriesEditPage />} />
        <Route path={PATHS.FEED} element={<FeedPage />} />
        <Route path={PATHS.NEWS} element={<NewsPage />} />
        <Route path={PATHS.SOURCES} element={<SourcesPage />} />
        <Route path={PATHS.SOURCES_ADD} element={<SourcesAddPage />} />
        <Route path={PATHS.SOURCES_EDIT} element={<SourcesEditPage />} />
        <Route path={PATHS.STATISTICS} element={<StatisticsPage />} />
        <Route path={PATHS.STOPWORDS} element={<StopwordsPage />} />
        <Route path={PATHS.STOPWORDS_ADD} element={<StopwordsAddPage />} />
        <Route path={PATHS.STOPWORDS_EDIT} element={<StopwordsEditPage />} />
        <Route path={PATHS.WIDGETS} element={<WidgetsPage />} />
      </Route>

      {/* ADMIN ROUTES */}
      <Route element={<ProtectedRoutes allowedRoles={[ROLES.ADMIN]} />}>
        <Route path={PATHS.USERS} element={<UsersPage />} />
        <Route path={PATHS.USERS_ADD} element={<UsersAddPage />} />
        <Route path={PATHS.USERS_EDIT} element={<UsersEditPage />} />
      </Route>
    </Routes>
  </Router>
)

export default App
