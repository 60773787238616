import { useNavigate } from 'react-router-dom'

import { LayoutBase } from 'ui/layouts'

export const NotFoundPage = () => {
  const navigate = useNavigate()

  const goBack = () => navigate(-1)

  return (
    <LayoutBase>
      <div className="container my-auto text-center">
        <h1 className="mb-5 text-3xl font-medium">Страница не найдена</h1>

        <p className="mb-8 text-gray-400">
          Такой страницы не существует либо произошла ошибка.
        </p>

        <button
          className="w-52 bg-black text-white py-2 px-4 rounded-md font-semibold"
          onClick={goBack}
        >
          Назад
        </button>
      </div>
    </LayoutBase>
  )
}
