import { useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { SearchIcon, XIcon } from '@heroicons/react/outline'
import cn from 'classnames'

import { useNewsSearch } from 'hooks/useNewsSearch'

export const Search = ({ className }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { query, setQuery } = useNewsSearch()
  const inputRef = useRef()

  useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isExpanded, inputRef])

  const onQueryChange = useDebouncedCallback(e => setQuery(e.target.value), 500)

  const onSearchToggle = () => {
    if (!isExpanded) {
      setIsExpanded(true)
      return
    }

    if (query && query.length > 0) {
      setQuery('')
    }

    setIsExpanded(false)
  }

  const wrapperClass = cn(
    'absolute top-1/2 right-[24px] -translate-y-2/4  flex items-center justify-end transition-all overflow-hidden h-[26px]',
    className,
    {
      'w-6': !isExpanded,
      'w-60': isExpanded,
    }
  )

  return (
    <div className={wrapperClass}>
      {isExpanded && (
        <input
          ref={inputRef}
          className="absolute top-0 right-[28px] px-2 transition-all border border-[#ccc] rounded focus:outline-none"
          type="text"
          name="search_query"
          placeholder="Поиск"
          onChange={onQueryChange}
        />
      )}
      <button
        className="flex items-center justify-center shrink-0 w-6 h-6"
        onClick={onSearchToggle}
      >
        {isExpanded ? (
          <XIcon className="w-5 h-5" />
        ) : (
          <SearchIcon className="w-5 h-5" />
        )}
      </button>
    </div>
  )
}
