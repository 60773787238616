import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const schema = yup.object({
  email: yup.string().email('Укажите валидный email').required('Укажите email'),
  password: yup.string().required('Укажите ваш пароль'),
})

export const LoginForm = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="p-4 rounded-md shadow max-w-[420px] w-full"
    >
      <img src="/img/logo.svg" alt="" width="180px" className="mb-4 mx-auto" />

      <div className="mb-4">
        <label htmlFor="user_email" className="block mb-2 font-bold">
          Email
        </label>
        <input
          type="email"
          id="user_email"
          {...register('email')}
          className="w-full p-2 border rounded focus:border-sky-600 outline-none"
        />
        {errors.email && (
          <span className="text-xs text-red-500">{errors.email.message}</span>
        )}
      </div>

      <div className="mb-4">
        <label htmlFor="user_password" className="block mb-2 font-bold">
          Пароль
        </label>
        <input
          type="password"
          id="user_password"
          {...register('password')}
          className="w-full p-2 border rounded focus:border-sky-600 outline-none"
        />
        {errors.password && (
          <span className="text-xs text-red-500">
            {errors.password.message}
          </span>
        )}
      </div>

      <button
        type="submit"
        className="bg-green-500 text-white py-2 px-4 rounded-md font-semibold"
      >
        Войти
      </button>
    </form>
  )
}
