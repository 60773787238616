import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { BASE_ERROR_MESSAGE } from 'constants/messages'
import api from 'api/api'

export const fetchSources = createAsyncThunk(
  'sources/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.sources.fetch()

      return response
        ? response.data.sources
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const addSource = createAsyncThunk(
  'sources/add',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.sources.add(payload)

      return response ? response.data : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const editSource = createAsyncThunk(
  'sources/edit',
  async (payload, { rejectWithValue }) => {
    try {
      const { id, ...params } = payload

      const response = await api.sources.edit({ id, params })

      return response && response.status === 204
        ? payload
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const deleteSource = createAsyncThunk(
  'sources/delete',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.sources.delete(payload)

      return response.status === 204
        ? payload
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

const initialState = {
  data: [],
  loading: false,
  error: false,
}

export const sourcesSlice = createSlice({
  name: 'sources',
  initialState,
  extraReducers: {
    [fetchSources.pending]: state => {
      state.loading = true
    },
    [fetchSources.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = false
      state.error = false
    },
    [fetchSources.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [addSource.pending]: state => {
      state.loading = true
    },
    [addSource.fulfilled]: (state, { payload }) => {
      state.data = [...state.data, payload]
      state.loading = false
      state.error = false
    },
    [addSource.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [editSource.pending]: state => {
      state.loading = true
    },
    [editSource.fulfilled]: (state, { payload }) => {
      state.data = state.data.map(source =>
        source.id === payload.id ? payload : source
      )
      state.loading = false
      state.error = false
    },
    [editSource.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [deleteSource.pending]: state => {
      state.loading = true
    },
    [deleteSource.fulfilled]: (state, { payload }) => {
      state.data = state.data.filter(item => item.id !== payload)
      state.loading = false
      state.error = false
    },
    [deleteSource.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})

export default sourcesSlice.reducer
