import cn from 'classnames'

export const Table = ({ children, className, variant }) => {
  const tableClass = cn('w-full', className, {
    'is-news-table': variant === 'news',
    'table-responsive': variant === 'responsive',
  })

  return <table className={tableClass}>{children}</table>
}

const Head = ({ children, ...props }) => (
  <thead className="text-xs text-center font-bold text-[#717171]">
    {children}
  </thead>
)

const HeadCell = ({ children }) => <th className="py-4 px-1">{children}</th>

const Body = ({ children }) => (
  <tbody className="text-xs font-medium">{children}</tbody>
)

const Row = ({ children }) => (
  // <tr className="text-center border-b last:border-b-0">{children}</tr>
  <tr className="text-center lg:border-b last:border-b-0 min-w-0">
    {children}
  </tr>
)

const Cell = ({ children, className, ...props }) => (
  <td className={cn('py-4 px-3 min-w-0', className)} {...props}>
    {children}
  </td>
)

Table.Head = Head
Table.HeadCell = HeadCell
Table.Body = Body
Table.Row = Row
Table.Cell = Cell
