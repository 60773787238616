import { useForm } from 'react-hook-form'
import cn from 'classnames'

import { Checkbox } from 'ui/atoms'
import { Table } from 'ui/molecules'

const WIDGETS_NAMES = {
  covid: 'Covid-19',
  currency: 'Курсы валют',
  weather: 'Прогноз погоды',
  camera: 'Камеры',
}

export const WidgetsForm = ({ defaultValues, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Table className="mb-12">
        <Table.Head>
          <tr>
            <Table.HeadCell>№</Table.HeadCell>
            <Table.HeadCell>Виджет</Table.HeadCell>
            <Table.HeadCell>Отображать на сайте</Table.HeadCell>
          </tr>
        </Table.Head>

        <Table.Body>
          {Object.keys(defaultValues).map((widget, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{idx + 1}</Table.Cell>
              <Table.Cell>{WIDGETS_NAMES[widget] || widget}</Table.Cell>
              <Table.Cell>
                <Checkbox {...register(`${widget}`)} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <div className="flex items-center justify-end">
        <button
          className={cn('text-white py-2 px-4 rounded-md font-semibold', {
            'bg-green-500': isDirty,
            'bg-green-300 cursor-not-allowed': !isDirty,
          })}
          type="submit"
          disabled={!isDirty}
        >
          Сохранить
        </button>
      </div>
    </form>
  )
}
