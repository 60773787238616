import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { BASE_ERROR_MESSAGE } from 'constants/messages'
import api from 'api/api'

export const fetchUsers = createAsyncThunk(
  'users/fetch',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.users.fetch()

      return response
        ? response.data.moderators
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const addUser = createAsyncThunk(
  'users/add',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.users.add(payload)

      return response ? response.data : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const editUser = createAsyncThunk(
  'users/edit',
  async (payload, { rejectWithValue }) => {
    try {
      const { id, ...params } = payload
      const response = await api.users.edit({ id, params })

      return response && response.status === 204
        ? payload
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const deleteUser = createAsyncThunk(
  'users/delete',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.users.delete(payload)

      return response && response.status === 204
        ? payload
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

const initialState = {
  data: [],
  loading: false,
  error: false,
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  extraReducers: {
    [fetchUsers.pending]: state => {
      state.loading = true
    },
    [fetchUsers.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = false
      state.error = false
    },
    [fetchUsers.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [addUser.pending]: state => {
      state.loading = true
    },
    [addUser.fulfilled]: (state, { payload }) => {
      state.data = [...state.data, payload]
      state.loading = false
      state.error = false
    },
    [addUser.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [editUser.pending]: state => {
      state.loading = true
    },
    [editUser.fulfilled]: (state, { payload }) => {
      state.data = state.data.map(user =>
        user.id === payload.id ? payload : user
      )
      state.loading = false
      state.error = false
    },
    [editUser.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [deleteUser.pending]: state => {
      state.loading = true
    },
    [deleteUser.fulfilled]: (state, { payload }) => {
      state.data = state.data.filter(user => user.id !== payload)
      state.loading = false
      state.error = false
    },
    [deleteUser.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})

export default usersSlice.reducer
