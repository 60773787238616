import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  stopwordsFetch,
  stopwordsAdd,
  stopwordsEdit,
  stopwordsDelete,
} from 'redux/slices/stopwordsSlice'
import {
  getStowordsData,
  getStowordsError,
  getStowordsLoadingState,
} from 'redux/selectors/stopwordsSelectors'

export const useStopwordsManager = () => {
  const stopwords = useSelector(getStowordsData)
  const error = useSelector(getStowordsError)
  const isLoading = useSelector(getStowordsLoadingState)
  const dispatch = useDispatch()

  const loadStopwords = useCallback(() => {
    dispatch(stopwordsFetch())
  }, [])

  const addStopword = useCallback(word => dispatch(stopwordsAdd(word)), [])

  const editStopword = useCallback(word => dispatch(stopwordsEdit(word)), [])

  const deleteStopword = useCallback(
    word => dispatch(stopwordsDelete(word)),
    []
  )

  return {
    stopwords,
    error,
    isLoading,
    loadStopwords,
    addStopword,
    editStopword,
    deleteStopword,
  }
}
