import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { CategoriesForm } from 'ui/organisms'
import { LayoutBase } from 'ui/layouts'

import { PATHS } from 'routing/paths'
import { addCategory } from 'redux/slices/categoriesSlice'

export const CategoriesAddPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onCategoriesFormSubmit = async data => {
    try {
      const response = await dispatch(addCategory(data)).unwrap()

      navigate(PATHS.CATEGORIES)
    } catch (error) {
      alert(error)
    }
  }

  const onCategoriesFormCancel = () => navigate(PATHS.CATEGORIES)

  return (
    <LayoutBase>
      <div className="container mx-auto pt-6 pb-12 px-4">
        <h1 className="mb-6 text-[32px] font-semibold">Добавить категорию</h1>

        <CategoriesForm
          onSubmit={onCategoriesFormSubmit}
          onCancel={onCategoriesFormCancel}
        />
      </div>
    </LayoutBase>
  )
}
