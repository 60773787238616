import { Table } from 'ui/molecules'

export const UsersTable = ({ users, onDelete, onEdit }) => (
  <Table variant="responsive">
    <Table.Head>
      <tr>
        <Table.HeadCell>№</Table.HeadCell>
        <Table.HeadCell>ФИО</Table.HeadCell>
        <Table.HeadCell>E-mail</Table.HeadCell>
        <Table.HeadCell>Действие</Table.HeadCell>
      </tr>
    </Table.Head>

    <Table.Body>
      {users.map((user, idx) => (
        <Table.Row key={user.id}>
          <Table.Cell className="is-hidden-mobile">{idx + 1}</Table.Cell>
          <Table.Cell className="has-title" data-title="ФИО:">
            {user.name}
          </Table.Cell>
          <Table.Cell className="has-title" data-title="Email:">
            {user.email}
          </Table.Cell>
          <Table.Cell>
            <div className="flex items-center justify-center">
              <button
                className="bg-green-500 text-white mr-2 py-2 px-4 rounded-md font-semibold"
                onClick={onEdit.bind(null, user)}
              >
                Изменить
              </button>
              <button
                className="bg-red-100 text-red-500 py-2 px-4 rounded-md font-semibold"
                onClick={onDelete.bind(null, user)}
              >
                Удалить
              </button>
            </div>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)
