import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { Preloader } from 'ui/molecules'
import { UsersTable } from 'ui/organisms'
import { LayoutBase } from 'ui/layouts'

import { PATHS } from 'routing/paths'
import { fetchUsers, deleteUser } from 'redux/slices/usersSlice'
import {
  selectUsersData,
  selectUsersLoadingState,
} from 'redux/selectors/usersSelectors'

export const UsersPage = () => {
  const users = useSelector(selectUsersData)
  const isLoading = useSelector(selectUsersLoadingState)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchUsers())
  }, [])

  const onUserEdit = user => navigate(PATHS.USERS_EDIT, { state: { user } })

  const onUserDelete = user => {
    let isConfirmed = window.confirm(
      `Вы уверены, что хотите удалить пользователя ${user.name}?`
    )

    if (isConfirmed) {
      dispatch(deleteUser(user.id))
    }

    return null
  }

  return (
    <LayoutBase>
      <div className="container  mx-auto pt-6 pb-12 px-4">
        <header className="mb-10">
          <h1 className="mb-6 text-[32px] font-semibold">Пользователи</h1>
          <Link
            to={PATHS.USERS_ADD}
            className="bg-green-500 text-white py-2 px-4 rounded-md font-semibold"
          >
            Добавить пользователя
          </Link>
        </header>

        {isLoading ? (
          <Preloader />
        ) : (
          <UsersTable
            users={users}
            onDelete={onUserDelete}
            onEdit={onUserEdit}
          />
        )}
      </div>
    </LayoutBase>
  )
}
