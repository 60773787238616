import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import api from 'api/api'
import { BASE_ERROR_MESSAGE } from 'constants/messages'

export const fetchBanners = createAsyncThunk(
  'banners/fetch',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.banners.fetch()

      return response
        ? response.data.banners
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const addBanner = createAsyncThunk(
  'banners/add',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.banners.add(payload)

      return response ? response.data : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const editBanner = createAsyncThunk(
  'banners/edit',
  async (payload, { rejectWithValue }) => {
    try {
      const { id, data } = payload
      const response = await api.banners.edit({ id, params: data })

      return response && response.status === 204
        ? Object.keys(payload).filter(key => key !== 'image')
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const deleteBanner = createAsyncThunk(
  'banners/delete',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.banners.delete(payload)

      return response && response.status === 204
        ? payload
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

const initialState = {
  data: [],
  loading: false,
  error: false,
}

export const bannersSlice = createSlice({
  name: 'banners',
  initialState,
  extraReducers: {
    [fetchBanners.pending]: state => {
      state.loading = true
    },
    [fetchBanners.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = false
      state.error = false
    },
    [fetchBanners.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [addBanner.pending]: state => {
      state.loading = true
    },
    [addBanner.fulfilled]: (state, { payload }) => {
      state.data = [...state.data, payload]
      state.loading = false
      state.error = false
    },
    [addBanner.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [editBanner.pending]: state => {
      state.loading = true
    },
    [editBanner.fulfilled]: (state, { payload }) => {
      state.data = state.data.map(banner =>
        banner.id === payload.id ? payload : banner
      )
      state.loading = false
      state.error = false
    },
    [editBanner.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [deleteBanner.pending]: state => {
      state.loading = true
    },
    [deleteBanner.fulfilled]: (state, { payload }) => {
      state.data = state.data.filter(item => item.id !== payload)
      state.loading = false
      state.error = false
    },
    [deleteBanner.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})

export default bannersSlice.reducer
