import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { Preloader } from 'ui/molecules'
import { CategoriesTable } from 'ui/organisms'
import { LayoutBase } from 'ui/layouts'

import { PATHS } from 'routing/paths'
import { fetchCategories, deleteCategory } from 'redux/slices/categoriesSlice'
import {
  selectCategoriesData,
  selectCategoriesLoadingState,
} from 'redux/selectors/categoriesSelectors'

export const CategoriesPage = () => {
  const data = useSelector(selectCategoriesData)
  const isLoading = useSelector(selectCategoriesLoadingState)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchCategories())
  }, [])

  const onCategoryEdit = category =>
    navigate(PATHS.CATEGORIES_EDIT, { state: { category } })

  const onCategoryDelete = category => {
    let isConfirmed = window.confirm(
      `Вы уверены, что хотите удалить категорию ${category.name}?`
    )

    if (isConfirmed) {
      dispatch(deleteCategory(category.id))
    }

    return null
  }

  return (
    <LayoutBase>
      <div className="container mx-auto pt-6 pb-12 px-4 bg-neutral-100 lg:bg-transparent">
        <header className="mb-10">
          <h1 className="mb-6 text-[32px] font-semibold">Категории</h1>

          <Link
            to={PATHS.CATEGORIES_ADD}
            className="bg-green-500 text-white py-2 px-4 rounded-md font-semibold"
          >
            Добавить категорию
          </Link>
        </header>

        {isLoading ? (
          <Preloader />
        ) : (
          <CategoriesTable
            categories={data}
            onEdit={onCategoryEdit}
            onDelete={onCategoryDelete}
          />
        )}
      </div>
    </LayoutBase>
  )
}
