import { useSelector } from 'react-redux'

import { ROLES } from 'constants/roles'
import { selectUserRole } from 'redux/selectors/authSelectors'

export const Footer = () => {
  const role = useSelector(selectUserRole)

  const text =
    role === ROLES.MODERATOR ? 'Панель модератора' : 'Панель администратора'

  return (
    <footer className="bg-neutral-100 py-4">
      <div className="container mx-auto flex items-center justify-between">
        <p className="text-lg font-semibold">Yanta.ru</p>

        <p className="font-medium text-[#a9a9a9]">{text}</p>
      </div>
    </footer>
  )
}
