import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import api from 'api/api'
import { BASE_ERROR_MESSAGE } from 'constants/messages'

export const fetchAutopubStatus = createAsyncThunk(
  'autopub/fetch',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.autopub.fetch()

      return response ? response.data : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const saveAutopubStatus = createAsyncThunk(
  'autopub/save',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.autopub.edit(payload)

      return response && response.status === 204
        ? payload
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

const initialState = {
  data: [],
  loading: false,
  error: false,
}

export const autopubSlice = createSlice({
  name: 'autopub',
  initialState,
  extraReducers: {
    [fetchAutopubStatus.pending]: state => {
      state.loading = true
    },
    [fetchAutopubStatus.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = false
      state.error = false
    },
    [fetchAutopubStatus.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [saveAutopubStatus.pending]: state => {
      state.loading = true
    },
    [saveAutopubStatus.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = false
      state.error = false
    },
    [saveAutopubStatus.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})

export default autopubSlice.reducer
