import * as R from 'ramda'

const REGION_NEWS_SETTING_PROPS = [
  'display_kaliningrad_time',
  'display_tag_kd',
  'display_news_feed_default',
  'region_proportion_on',
]

export const getRegionNewsSettingsProps = R.pick(REGION_NEWS_SETTING_PROPS)

export const normalizeRegionNewsSettingsProps = R.evolve({
  display_news_feed_default: R.toString,
})
