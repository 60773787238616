import { useEffect } from 'react'

import { LayoutBase } from 'ui/layouts'
import { StatisticsTable, StatisticsFilters } from 'ui/organisms'

import { useStatisticsManager } from 'hooks/useStatisticsManager'

const StatisticsError = () => (
  <p className="text-center">Произошла ошибка. Попробуйте позже.</p>
)

const StatisticsLoading = () => <p className="text-center">Загрузка...</p>

export const StatisticsPage = () => {
  const { statistics, error, isLoading, loadStatistics } =
    useStatisticsManager()

  useEffect(() => {
    // loadStatistics({ deep: 1, unit: 'day' })
  }, [])

  return (
    <LayoutBase>
      <StatisticsFilters />
      <div className="container  mx-auto pt-6 pb-12 px-4 bg-neutral-100 lg:bg-transparent">
        <header className="mb-10">
          <h1 className="mb-6 text-[32px] font-semibold">Статистика</h1>
        </header>

        {isLoading && <StatisticsLoading />}
        {!isLoading && error && <StatisticsError />}
        {!isLoading && statistics.length && (
          <StatisticsTable statistics={statistics} />
        )}
      </div>
    </LayoutBase>
  )
}
