import Select from 'react-select'

import { NEWS_STATUSES_NAMES } from 'constants/news'
import { getStatusById } from 'utils/status'

const customStyles = {
  container: provided => ({
    ...provided,
    minWidth: '240px',
    maxWidth: '240px',
  }),
  menuList: provided => ({
    ...provided,
    textAlign: 'left',
  }),
}

const statuses = Object.entries(NEWS_STATUSES_NAMES).map(([key, value]) => ({
  label: value,
  value: key,
}))

export const StatusSelect = ({ defaultValues, onChange }) => {
  return (
    <Select
      defaultValue={getStatusById(statuses, defaultValues)}
      styles={customStyles}
      options={statuses}
      placeholder="Выбрать статус"
      isClearable={false}
      onChange={onChange}
    />
  )
}
