import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { UsersAddForm } from 'ui/organisms'
import { LayoutBase } from 'ui/layouts'

import { PATHS } from 'routing/paths'
import { addUser } from 'redux/slices/usersSlice'

export const UsersAddPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onUsersAddFormSubmit = async data => {
    try {
      const response = await dispatch(addUser(data)).unwrap()

      navigate(PATHS.USERS)
    } catch (error) {
      alert(error)
    }
  }

  const onUsersAddFormCancel = () => navigate(PATHS.USERS)

  return (
    <LayoutBase>
      <div className="container mx-auto pt-6 pb-12 px-4">
        <h1 className="mb-6 text-[32px] font-semibold">
          Добавить пользователя
        </h1>

        <UsersAddForm
          onCancel={onUsersAddFormCancel}
          onSubmit={onUsersAddFormSubmit}
        />
      </div>
    </LayoutBase>
  )
}
