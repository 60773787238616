import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setSearchQuery } from 'redux/slices/newsSlice'
import { getNewsSearchQuery } from 'redux/selectors/newsSelectors'

export const useNewsSearch = () => {
  const query = useSelector(getNewsSearchQuery)
  const dispatch = useDispatch()

  const isSearching = useMemo(() => {
    return !!query && query.length > 0
  }, [query])

  const setQuery = query => dispatch(setSearchQuery(query))

  const resetQuery = () => dispatch(setSearchQuery(''))

  return { query, setQuery, resetQuery, isSearching }
}
