import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { Checkbox, ErrorMessage, Field, Input, Label } from 'ui/atoms'

const schema = yup.object({
  name: yup.string().required('Укажите имя категории'),
})

export const CategoriesForm = ({
  defaultValues = { active: true },
  onSubmit,
  onCancel,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-2xl width-full">
      <Field>
        <Label>Имя категории</Label>
        <Input className="input" type="text" {...register('name')} />
        {errors.name && <ErrorMessage message={errors.name.message} />}
      </Field>

      <Field>
        <Checkbox title="Автопубликация" {...register('autopub')} />
      </Field>

      <Field className="mb-12">
        <Checkbox title="Активная" {...register('active')} />
      </Field>

      <div className="flex items-center justify-center">
        <button
          className="bg-green-500 text-white py-2 px-4 rounded-md font-semibold mr-2"
          type="submit"
        >
          Сохранить
        </button>
        <button
          className="bg-red-100 text-red-500 py-2 px-4 rounded-md font-semibold"
          type="button"
          onClick={onCancel}
        >
          Отмена
        </button>
      </div>
    </form>
  )
}
