import * as R from 'ramda'

export const selectNewsData = R.path(['news', 'data', 'news'])

export const selectNewsPaging = R.path(['news', 'data', 'page'])

export const selectNewsLoadingState = R.path(['news', 'loading'])

export const selectNewsCategoriesFilter = R.path([
  'news',
  'filters',
  'categories',
])

export const selectNewsSourcesFilter = R.path(['news', 'filters', 'sources'])

export const selectNewsStatusFilter = R.path(['news', 'filters', 'status'])

export const getNewsSearchQuery = R.path(['news', 'search', 'query'])
