export const ENDPOINTS = {
  AUTH: '/user/auth',
  AUTOPUB: '/moderate/autopub',
  BANNERS: '/moderate/banners',
  CATEGORIES: '/moderate/categories',
  CHANGE_PASSWORD: '/user/change_password',
  NEWS: '/moderate/news',
  REGIONS: '/moderate/regions',
  REGIONS_STATUS: '/moderate/regions/proportion',
  REGION_NEWS_SETTINGS: '/moderate/settings/news_feed',
  SOURCES: '/moderate/sources',
  SOURCES_REGIONS: '/moderate/sources/regions',
  STATISTICS: '/moderate/statistics',
  STOPWORDS: '/moderate/stopwords',
  USERS: '/administrate/moderators',
  WIDGETS: '/moderate/widgets',
}
