import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import * as R from 'ramda'

import {
  selectCategoriesData,
  selectCategoriesLoadingState,
} from 'redux/selectors/categoriesSelectors'

import { getCategoriesById } from 'utils/categories'

const SelectComponents = {
  DropdownIndicator: null,
}

const customStyles = {
  container: provided => ({
    ...provided,
    minWidth: '140px',
    maxWidth: '240px',
  }),

  menuList: provided => ({
    ...provided,
    textAlign: 'left',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#000',
  }),
}

export const CategoriesSelect = ({
  defaultValues,
  isDisabled = false,
  onChange = () => {},
}) => {
  const categories = useSelector(selectCategoriesData)
  const isLoading = useSelector(selectCategoriesLoadingState)

  const onSelectChange = data => onChange(data)

  return (
    <Select
      components={SelectComponents}
      styles={customStyles}
      defaultValue={getCategoriesById(categories, defaultValues)}
      options={categories}
      placeholder="Выбрать категорию"
      isMulti
      isClearable={false}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onChange={onSelectChange}
      getOptionLabel={R.prop('name')}
      getOptionValue={R.prop('id')}
    />
  )
}
