import { Outlet } from 'react-router-dom'

import { LayoutBase } from 'ui/layouts'

export const Banners = () => {
  return (
    <LayoutBase>
      <div className="container mx-auto pt-6 pb-12 px-4 bg-neutral-100 lg:bg-transparent">
        <Outlet />
      </div>
    </LayoutBase>
  )
}
