import * as R from 'ramda'

export const getSourcesById = (arr = [], ids = []) =>
  arr.filter(i => ids.includes(i.id))

// const predicate = item => [2, 10].includes(item.id)
const predicate = R.compose(R.includes(R.__, [2, 10]), R.prop('id'))

export const getSourcesByIdNew = R.compose(R.filter(predicate))

export const getSourceNameById = (sources, id) =>
  R.compose(R.prop('name'), R.head(), R.filter(R.propEq('id', id)))(sources)

export const getSourceRegionById = (regions, id) =>
  R.compose(R.prop('name'), R.head(), R.filter(R.propEq('id', id)))(regions)
