import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { Checkbox, ErrorMessage, Field, Input, Label } from 'ui/atoms'

const schema = yup.object({
  name: yup.string().required('Укажите имя пользователя'),
})

export const UsersEditForm = ({ defaultValues, onSubmit, onCancel }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) })

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-2xl width-full">
      <Field>
        <Label>ФИО пользователя</Label>
        <Input className="input" type="text" {...register('name')} />
        {errors.name && <ErrorMessage message={errors.name.message} />}
      </Field>

      <Field className="mb-12">
        <Checkbox title="Сбросить пароль" {...register('reset')} />
        <p className="text-xs">
          Новый пароль будет выслан на email пользователя
        </p>
      </Field>

      <div className="flex items-center justify-center">
        <button
          className="bg-green-500 text-white py-2 px-4 rounded-md font-semibold mr-2"
          type="submit"
        >
          Сохранить
        </button>
        <button
          className="bg-red-100 text-red-500 py-2 px-4 rounded-md font-semibold"
          type="button"
          onClick={onCancel}
        >
          Отмена
        </button>
      </div>
    </form>
  )
}
