import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Preloader } from 'ui/molecules'
import { AutopubForm } from 'ui/organisms'
import { LayoutBase } from 'ui/layouts'

import {
  fetchAutopubStatus,
  saveAutopubStatus,
} from 'redux/slices/autopubSlice'
import {
  selectAutopubData,
  selectAutopubLoadingState,
} from 'redux/selectors/autopubSelectors'

export const AutopubPage = () => {
  const data = useSelector(selectAutopubData)
  const isLoading = useSelector(selectAutopubLoadingState)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAutopubStatus())
  }, [])

  const onAutopubFormSubmit = async data => {
    try {
      const response = dispatch(saveAutopubStatus(data)).unwrap()
    } catch (error) {
      alert(error)
    }
  }

  return (
    <LayoutBase>
      <div className="container mx-auto pt-6 pb-12 px-4">
        <h1 className="mb-6 text-[32px] font-semibold">Автопубликация</h1>

        {isLoading ? (
          <Preloader />
        ) : (
          <AutopubForm defaultValues={data} onSubmit={onAutopubFormSubmit} />
        )}
      </div>
    </LayoutBase>
  )
}
