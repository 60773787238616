import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { BannersForm } from 'ui/organisms'

import { PATHS } from 'routing/paths'
import { addBanner } from 'redux/slices/bannersSlice'
import { selectBannersLoadingState } from 'redux/selectors/bannersSelectors'
import { buildFormData } from 'utils/form'

export const BannerWidgetAdd = () => {
  const isLoading = useSelector(selectBannersLoadingState)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onBannersFormSubmit = async data => {
    try {
      const formData = buildFormData(data)
      const response = await dispatch(addBanner(formData)).unwrap()
      navigate(PATHS.BANNERS_WIDGET)
    } catch (error) {
      alert(error)
    }
  }

  const onBannersFormCancel = data => navigate(PATHS.BANNERS_WIDGET)

  return (
    <Fragment>
      <h1 className="mb-6 text-[32px] font-semibold">Добавить баннер</h1>

      <BannersForm
        isLoading={isLoading}
        onSubmit={onBannersFormSubmit}
        onCancel={onBannersFormCancel}
      />
    </Fragment>
  )
}
