import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { statisticsFetch } from 'redux/slices/statisticsSlice'
import {
  getStatisticsData,
  getStatisticsError,
  getStatisticsLoadingState,
} from 'redux/selectors/statisticsSelectors'

export const useStatisticsManager = () => {
  const statistics = useSelector(getStatisticsData)
  const error = useSelector(getStatisticsError)
  const isLoading = useSelector(getStatisticsLoadingState)
  const dispatch = useDispatch()

  const loadStatistics = useCallback(
    params => dispatch(statisticsFetch(params)),
    []
  )

  return { statistics, error, isLoading, loadStatistics }
}
