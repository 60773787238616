import { NEWS_STATUSES } from 'constants/news'

const ModerateActions = ({ unit, onEdit }) => (
  <>
    <button
      className="grow lg:grow-0 bg-red-100 text-red-500 mr-2 py-2 px-4 rounded-md font-semibold"
      onClick={() => onEdit({ status: NEWS_STATUSES.CANCELED, id: unit.id })}
    >
      Отклонить
    </button>
    <button
      className="grow lg:grow-0 bg-green-500 text-white py-2 px-4 rounded-md font-semibold"
      onClick={() =>
        onEdit({
          status: NEWS_STATUSES.PUBLISHED,
          id: unit.id,
          category: unit.category.join(','),
        })
      }
    >
      Опубликовать
    </button>
  </>
)

const PubActions = ({ unit, onEdit }) => (
  <button
    className="w-full bg-neutral-100 py-2 px-4 rounded-md font-semibold"
    onClick={() => onEdit({ status: NEWS_STATUSES.REVOKED, id: unit.id })}
  >
    Отменить публикацию
  </button>
)

const NoActions = () => <p>Не требуется</p>

const RevokedActions = ({ unit, onEdit }) => (
  <button
    className="w-full bg-neutral-100 py-2 px-4 rounded-md font-semibold"
    onClick={() => onEdit({ status: NEWS_STATUSES.MODERATE, id: unit.id })}
  >
    Вернуть на проверку
  </button>
)

export const NewsActions = ({ status, ...props }) => {
  switch (status) {
    case NEWS_STATUSES.AUTO:
      return <PubActions {...props} />
    case NEWS_STATUSES.PUBLISHED:
      return <PubActions {...props} />
    case NEWS_STATUSES.MODERATE:
      return <ModerateActions {...props} />
    case NEWS_STATUSES.CANCELED:
      return <RevokedActions {...props} />
    case NEWS_STATUSES.REVOKED:
      return <RevokedActions {...props} />
    default:
      return <NoActions />
  }
}
