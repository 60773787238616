import Select from 'react-select'

// const SelectComponents = {
//   DropdownIndicator: null,
// }

const customStyles = {
  container: provided => ({
    ...provided,
    minWidth: '240px',
    maxWidth: '240px',
  }),
  menuList: provided => ({
    ...provided,
    textAlign: 'left',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#000',
  }),
}

const PERIODS = [
  { label: 'Дни', value: 'day' },
  { label: 'Часы', value: 'hour' },
  { label: 'Минуты', value: 'minute' },
]

export const StatUnitSelect = ({ defaultValue, onChange }) => (
  <Select
    defaultValue={defaultValue}
    styles={customStyles}
    options={PERIODS}
    placeholder="Выбрать период"
    isClearable={false}
    onChange={onChange}
  />
)
