import axios from 'axios'

import { ENDPOINTS } from './endpoints'
import { store } from 'redux/store'

const BASE_URL = process.env.REACT_APP_API_URI
const axs = axios.create({
  baseURL: BASE_URL,
})

const api = {
  auth: {
    login: credentials =>
      axs.post(ENDPOINTS.AUTH, null, { params: credentials }),
  },
  autopub: {
    fetch: () =>
      axs.get(ENDPOINTS.AUTOPUB, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    edit: params =>
      axs.put(ENDPOINTS.AUTOPUB, null, {
        params,
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
  },
  banners: {
    fetch: () =>
      axs.get(ENDPOINTS.BANNERS, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    fetchSingle: id =>
      axs.get(`${ENDPOINTS.BANNERS}/${id}`, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    add: params =>
      axs.post(ENDPOINTS.BANNERS, params, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      }),
    edit: ({ id, params }) =>
      axs.put(`${ENDPOINTS.BANNERS}/${id}`, params, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      }),
    delete: id =>
      axs.delete(`${ENDPOINTS.BANNERS}/${id}`, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    fetchBannerImg: id =>
      axs.get(`${ENDPOINTS.BANNERS}/${id}/image`, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
        responseType: 'blob',
      }),
  },
  categories: {
    fetch: () =>
      axs.get(ENDPOINTS.CATEGORIES, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    fetchSingle: id =>
      axs.get(`${ENDPOINTS.CATEGORIES}/${id}`, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    add: params =>
      axs.post(ENDPOINTS.CATEGORIES, null, {
        params,
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    edit: ({ id, params }) =>
      axs.put(`${ENDPOINTS.CATEGORIES}/${id}`, null, {
        params,
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    delete: id =>
      axs.delete(`${ENDPOINTS.CATEGORIES}/${id}`, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
  },
  news: {
    fetch: params =>
      axs.get(ENDPOINTS.NEWS, {
        ...params,
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    edit: ({ id, params }) =>
      axs.patch(`${ENDPOINTS.NEWS}/${id}`, null, {
        params,
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
  },
  regions: {
    fetch: () =>
      axs.get(ENDPOINTS.REGIONS, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    edit: params =>
      axs.put(ENDPOINTS.REGIONS, null, {
        params,
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    fetchStatus: () =>
      axs.get(ENDPOINTS.REGIONS_STATUS, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    editStatus: params =>
      axs.put(ENDPOINTS.REGIONS_STATUS, null, {
        params,
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    fetchNewsSettings: () =>
      axs.get(ENDPOINTS.REGION_NEWS_SETTINGS, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    editNewsSettings: params =>
      axs.put(ENDPOINTS.REGION_NEWS_SETTINGS, null, {
        params,
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
  },
  sources: {
    fetch: () =>
      axs.get(ENDPOINTS.SOURCES, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    fetchSingle: id =>
      axs.get(`${ENDPOINTS.SOURCES}/${id}`, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    add: params =>
      axs.post(ENDPOINTS.SOURCES, null, {
        params,
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    edit: ({ id, params }) =>
      axs.put(`${ENDPOINTS.SOURCES}/${id}`, null, {
        params,
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    delete: id =>
      axs.delete(`${ENDPOINTS.SOURCES}/${id}`, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    fetchRegions: () =>
      axs.get(ENDPOINTS.SOURCES_REGIONS, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
  },
  statistics: {
    fetch: params =>
      axs.get(ENDPOINTS.STATISTICS, {
        params,
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
  },
  stopwords: {
    fetch: () =>
      axs.get(ENDPOINTS.STOPWORDS, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    add: params =>
      axs.post(ENDPOINTS.STOPWORDS, null, {
        params,
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    edit: ({ id, params }) =>
      axs.put(`${ENDPOINTS.STOPWORDS}/${id}`, params, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
          'Content-Type': 'multipart/form-data',
        },
      }),
    delete: id =>
      axs.delete(`${ENDPOINTS.STOPWORDS}/${id}`, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
  },
  users: {
    fetch: () =>
      axs.get(ENDPOINTS.USERS, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    add: params =>
      axs.post(ENDPOINTS.USERS, null, {
        params,
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    edit: ({ id, params }) =>
      axs.patch(`${ENDPOINTS.USERS}/${id}`, null, {
        params,
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    delete: id =>
      axs.delete(`${ENDPOINTS.USERS}/${id}`, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
  },
  widgets: {
    fetch: () =>
      axs.get(ENDPOINTS.WIDGETS, {
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
    edit: params =>
      axs.put(ENDPOINTS.WIDGETS, null, {
        params,
        headers: {
          Authorization: `Bearer ${store.getState().auth.user?.access_token}`,
        },
      }),
  },
}

export default api
