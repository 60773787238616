import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { BASE_ERROR_MESSAGE } from 'constants/messages'
import api from 'api/api'
import { saveUserToLocalStorage, removeUserFromLocalStorage } from 'utils/auth'

const initialState = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: false,
}

export const login = createAsyncThunk(
  'auth/login',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.auth.login(payload)

      saveUserToLocalStorage(response.data)

      return response ? response.data : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const logout = createAsyncThunk('auth/logout', async () => {
  removeUserFromLocalStorage()
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    restoreSession: (state, { payload }) => {
      state.isAuthenticated = true
      state.user = payload
    },
  },
  extraReducers: {
    [login.pending]: state => {
      state.loading = true
    },
    [login.fulfilled]: (state, { payload }) => {
      state.isAuthenticated = true
      state.user = payload
      state.loading = false
      state.error = false
    },
    [login.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [logout.fulfilled]: state => {
      state.isAuthenticated = false
      state.user = null
    },
  },
})

export const { restoreSession } = authSlice.actions

export default authSlice.reducer
