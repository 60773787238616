import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { BASE_ERROR_MESSAGE } from 'constants/messages'
import api from 'api/api'

const initialState = {
  data: [],
  loading: false,
  error: null,
}

export const statisticsFetch = createAsyncThunk(
  'statistics/fetch',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.statistics.fetch(payload)

      return response
        ? response.data.statistics
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  extraReducers: {
    [statisticsFetch.pending]: state => {
      state.loading = true
    },
    [statisticsFetch.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = false
      state.error = null
    },
    [statisticsFetch.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})

export default statisticsSlice.reducer
