import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Preloader } from 'ui/molecules'
import { FeedForm } from 'ui/organisms'
import { LayoutBase } from 'ui/layouts'

import { getRegionNewsSettingsProps } from 'utils/regions'
import {
  fetchRegions,
  editRegions,
  fetchRegionsStatus,
  editRegionsStatus,
  fetchRegionNewsSettings,
  editRegionNewsSettings,
} from 'redux/slices/regionsSlice'
import {
  selectRegionsLoadingState,
  selectRegionsFullData,
} from 'redux/selectors/regionsSelectors'
import { BASE_ERROR_MESSAGE } from 'constants/messages'

export const FeedPage = () => {
  const regionsData = useSelector(selectRegionsFullData)
  const isLoading = useSelector(selectRegionsLoadingState)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchRegions()).unwrap()
      // await dispatch(fetchRegionsStatus()).unwrap()
      await dispatch(fetchRegionNewsSettings()).unwrap()
    }

    fetchData()
  }, [])

  const onFeedFormSubmit = async data => {
    const regionNewsSettings = getRegionNewsSettingsProps(data)

    try {
      // await dispatch(editRegionsStatus(data)).unwrap()

      if (data.region_proportion_on) {
        await dispatch(editRegions(data)).unwrap()
      }

      await dispatch(editRegionNewsSettings(regionNewsSettings)).unwrap()

      alert('Изменения сохранены.')
    } catch (error) {
      alert(error.response.data.description || BASE_ERROR_MESSAGE)
    }
  }

  return (
    <LayoutBase>
      <div className="container mx-auto pt-6 pb-12 px-4">
        <h1 className="mb-6 text-[32px] font-semibold">
          Настройка ленты новостей
        </h1>

        {isLoading ? (
          <Preloader />
        ) : (
          <FeedForm defaultValues={regionsData} onSubmit={onFeedFormSubmit} />
        )}
      </div>
    </LayoutBase>
  )
}
