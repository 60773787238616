export const buildFormData = object => {
  const formData = new FormData()

  Object.keys(object).forEach(key => {
    if (key === 'image' && object[key].length > 0) {
      formData.append(key, object[key][0])
    }

    if (key === 'display_num' && object[key] === null) {
      formData.append(key, '')
    }

    if (typeof object[key] !== 'object') {
      formData.append(key, object[key])
    } else {
      formData.append(key, JSON.stringify(object[key]))
    }
  })

  return formData
}
