import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import cn from 'classnames'

export const AutopubForm = ({ defaultValues, onSubmit }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm({ defaultValues })

  const isAutopub = watch('autopub')

  useEffect(() => {
    if (!isAutopub) {
      setValue('source_filter', false)
      setValue('category_filter', false)
      setValue('stopwords_filter', false)
    }
  }, [isAutopub])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-sm">
      <div className="flex items-center justify-between mb-6">
        <label className="text-[#f14668] font-bold" htmlFor="autopub">
          Автопубликация
        </label>
        <Tippy content="Если опция включена, то все новости, удовлетворяющие фильтрам, публикуются в автоматическом режиме. Если опция отключена, то все новости переводятся в статус 'на проверке' и ожидают действия модератора ">
          <input
            id="autopub"
            className="ml-2"
            type="checkbox"
            {...register('autopub')}
          />
        </Tippy>
      </div>

      <p className="font-bold mb-2">Настройка фильтров для автопубликации</p>

      <div className="flex items-center justify-between mb-3">
        <p>Фильтр 1: по источнику новости</p>
        <input
          className="ml-2"
          type="checkbox"
          {...register('source_filter')}
          disabled={!isAutopub}
        />
      </div>

      <div className="flex items-center justify-between mb-3">
        <p>Фультр 2: по категории новости</p>
        <input
          className="ml-2"
          type="checkbox"
          {...register('category_filter')}
          disabled={!isAutopub}
        />
      </div>

      <div className="flex items-center justify-between mb-12">
        <p>Фильтр 3: по стоп-словам в заголовке новости</p>
        <Tippy content="Если опция включена, то для автопубликаци, новости должны пройти фильтр. Если нововость не проходит фильтр, то она ставиться в статус 'На проверке' и ожидает действия от модератора. Если все фильры отключены, то все новости публикуются в автоматическом режиме.">
          <input
            className="ml-2"
            type="checkbox"
            {...register('stopwords_filter')}
            disabled={!isAutopub}
          />
        </Tippy>
      </div>

      <div className="flex justify-end">
        <button
          className={cn('text-white py-2 px-4 rounded-md font-semibold', {
            'bg-green-500': isDirty,
            'bg-green-300 cursor-not-allowed': !isDirty,
          })}
          type="submit"
          disabled={!isDirty}
        >
          Сохранить
        </button>
      </div>
    </form>
  )
}
