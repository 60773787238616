import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import * as R from 'ramda'

import {
  selectCategoriesData,
  selectCategoriesLoadingState,
} from 'redux/selectors/categoriesSelectors'

const SelectComponents = {
  DropdownIndicator: null,
}

const customStyles = {
  container: provided => ({
    ...provided,
    width: '240px',
  }),

  menuList: provided => ({
    ...provided,
    textAlign: 'left',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#000',
  }),
}

export const CategoriesFilterSelect = ({
  defaultValues,
  isDisabled = false,
  onChange = () => {},
}) => {
  const [selected, setSelected] = useState([])
  const categories = useSelector(selectCategoriesData)
  const isLoading = useSelector(selectCategoriesLoadingState)
  const options = selected.includes(-1)
    ? [{ id: -1, name: 'Без категории' }]
    : [{ id: -1, name: 'Без категории' }, ...categories]

  useEffect(() => {
    setSelected(defaultValues)
  }, [defaultValues])

  useEffect(() => {
    onChange(selected)
  }, [selected])

  const onSelectChange = e => {
    setSelected(e && e.length > 0 ? e.map(x => x.id) : [])
  }

  return (
    <Select
      components={SelectComponents}
      styles={customStyles}
      options={options}
      value={options.filter(x => selected.includes(x.id))}
      placeholder="Выбрать категорию"
      isMulti
      isClearable={true}
      isSearchable={false}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onChange={onSelectChange}
      getOptionLabel={R.prop('name')}
      getOptionValue={R.prop('id')}
      noOptionsMessage={() => null}
    />
  )
}
