import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RoleBasedRedirect } from 'ui/molecules'
import { LoginForm } from 'ui/organisms'

import { selectAuthStatus, selectUserRole } from 'redux/selectors/authSelectors'
import { login } from 'redux/slices/authSlice'

export const LoginPage = () => {
  const isAuth = useSelector(selectAuthStatus)
  const role = useSelector(selectUserRole)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isAuth) {
      return <RoleBasedRedirect role={role} />
    }
  }, [isAuth])

  const onLoginFormSubmit = async data => {
    try {
      const response = await dispatch(login(data)).unwrap()
    } catch (error) {
      alert(error)
    }
  }

  return (
    <div className="flex items-center justify-center min-h-screen p-4">
      <LoginForm onSubmit={onLoginFormSubmit} />
    </div>
  )
}
