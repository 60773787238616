import React from 'react'
import cn from 'classnames'

export const Input = React.forwardRef((props, ref) => (
  <input
    {...props}
    ref={ref}
    className={cn(
      'w-full p-2 border rounded focus:border-sky-600 outline-none',
      props.className
    )}
  />
))
