import { useMemo, useState } from 'react'
import { ArrowCircleRightIcon } from '@heroicons/react/outline'
import cn from 'classnames'

export const Pager = ({ total, size, initialPage, onPageClick, className }) => {
  const [page, setPage] = useState(initialPage || 1)
  const pageCount = useMemo(() => Math.ceil(total / size), [total, size])

  const handleSubmit = () => {
    if (page < 1 || page > pageCount) {
      alert('Недопустимое значение страницы!')
      return
    }

    onPageClick(page)
  }

  const pagerClass = cn('flex', className)

  return (
    <div className={pagerClass}>
      <input
        className="w-[100px] h-[38px] p-2 border rounded border-[#cccccc] focus:outline-none"
        type="text"
        onChange={e => setPage(Number(e.target.value))}
      />
      <button
        className="flex items-center justify-center w-[38px] h-[38px]"
        onClick={handleSubmit}
      >
        <ArrowCircleRightIcon className="w-6 h-6" />
      </button>
    </div>
  )
}
