import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { BASE_ERROR_MESSAGE } from 'constants/messages'
import api from 'api/api'

const initialState = {
  data: [],
  loading: false,
  error: null,
}

export const stopwordsFetch = createAsyncThunk(
  'stopwords/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.stopwords.fetch()

      return response
        ? response.data.stopwords
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const stopwordsAdd = createAsyncThunk(
  'stopwords/add',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.stopwords.add(payload)

      return response ? response.data : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const stopwordsEdit = createAsyncThunk(
  'stopwords/edit',
  async (payload, { rejectWithValue }) => {
    try {
      const { id, ...params } = payload

      const response = await api.stopwords.edit({ id, params })

      return response && response.status === 204
        ? payload
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const stopwordsDelete = createAsyncThunk(
  'stopwords/delete',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.stopwords.delete(payload)

      return response.status === 204
        ? payload
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const stopwordsSlice = createSlice({
  name: 'stopwords',
  initialState,
  extraReducers: {
    [stopwordsFetch.pending]: state => {
      state.loading = true
    },
    [stopwordsFetch.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = false
      state.error = null
    },
    [stopwordsFetch.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [stopwordsAdd.pending]: state => {
      state.loading = true
    },
    [stopwordsAdd.fulfilled]: (state, { payload }) => {
      state.data = [...state.data, payload]
      state.loading = false
      state.error = null
    },
    [stopwordsAdd.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [stopwordsEdit.pending]: state => {
      state.loading = true
    },
    [stopwordsEdit.fulfilled]: (state, { payload }) => {
      state.data = state.data.map(word =>
        word.id === payload.id ? payload : word
      )
      state.loading = false
      state.error = null
    },
    [stopwordsEdit.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [stopwordsDelete.pending]: state => {
      state.loading = true
    },
    [stopwordsDelete.fulfilled]: (state, { payload }) => {
      state.data = state.data.filter(word => word.id !== payload)
      state.loading = false
      state.error = null
    },
    [stopwordsDelete.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})

export default stopwordsSlice.reducer
