import { useEffect, useState } from 'react'

import api from 'api/api'

export const ImagePreview = ({ id, ...props }) => {
  const [img, setImg] = useState(null)

  const fetchImage = async () => {
    const response = await api.banners.fetchBannerImg(id)
    const reader = new FileReader()

    reader.readAsDataURL(response.data)
    reader.onloadend = () => setImg(reader.result)
  }

  useEffect(() => {
    fetchImage()
  }, [])

  return (
    <img
      src={img}
      alt=""
      className={`max-w-[200px] mx-auto ${props.className}`}
    />
  )
}
