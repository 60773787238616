import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { BASE_ERROR_MESSAGE } from 'constants/messages'
import api from 'api/api'
import { normalizeNewsPayload } from 'utils/news'

export const fetchNews = createAsyncThunk(
  'news/fetch',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.news.fetch({ params: payload })

      return response ? response.data : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const editNewsUnit = createAsyncThunk(
  'news/editunit',
  async (payload, { rejectWithValue }) => {
    try {
      const { id, ...params } = payload
      const response = await api.news.edit({ id, params })

      return response && response.status === 204
        ? payload
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

const initialState = {
  data: {
    news: [],
    page: {
      total: 0,
      size: 0,
    },
  },
  filters: {
    categories: [],
    sources: [],
    status: 'all',
  },
  search: {
    query: '',
  },
  loading: false,
  error: false,
}

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setCategoriesFilter: (state, { payload }) => {
      state.data.news = []
      state.data.page = {}
      state.filters.categories = payload
    },
    setSourcesFilter: (state, { payload }) => {
      state.data.news = []
      state.data.page = {}
      state.filters.sources = payload
    },
    setStatusFilter: (state, { payload }) => {
      state.data.news = []
      state.data.page = {}
      state.filters.status = payload
    },
    editNewsUnitCategories: (state, { payload }) => {
      state.data.news = state.data.news.map(item =>
        item.id === payload.id ? payload : item
      )
    },
    setSearchQuery: (state, { payload }) => {
      state.search.query = payload
      state.filters.categories = []
      state.filters.sources = []
      state.filters.status = 'all'
    },
  },
  extraReducers: {
    [fetchNews.pending]: state => {
      state.loading = true
    },
    [fetchNews.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = false
      state.error = false
    },
    [fetchNews.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [editNewsUnit.pending]: state => {
      state.loading = true
    },
    [editNewsUnit.fulfilled]: (state, { payload }) => {
      state.data.news = state.data.news.map(unit =>
        unit.id === payload.id
          ? Object.assign({}, unit, normalizeNewsPayload(payload))
          : unit
      )
      state.loading = false
      state.error = false
    },
    [editNewsUnit.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})

export const {
  setCategoriesFilter,
  setSourcesFilter,
  setStatusFilter,
  editNewsUnitCategories,
  setSearchQuery,
} = newsSlice.actions

export default newsSlice.reducer
