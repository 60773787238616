import { useSelector } from 'react-redux'

import { Table, CategoriesSelect } from 'ui/molecules'
import { NewsActions } from 'ui/organisms'

import { NEWS_STATUSES_NAMES, NEWS_STATUSES } from 'constants/news'
import { getDateTimeFromTimestamp } from 'utils/date'
import { getSourceNameById } from 'utils/sources'
import { selectSourcesData } from 'redux/selectors/sourcesSelectors'

const CategoryTools = ({ unit, onChange }) => {
  const isNotModerate = unit.status !== NEWS_STATUSES.MODERATE
  const isEmptyCategories = unit.category.length === 0

  return isNotModerate && isEmptyCategories ? (
    <span>-</span>
  ) : (
    <CategoriesSelect
      isDisabled={unit.status !== NEWS_STATUSES.MODERATE}
      defaultValues={unit.category}
      onChange={onChange.bind(null, unit)}
    />
  )
}

export const NewsTable = ({ news, onEdit, onCategoryChange }) => {
  const sources = useSelector(selectSourcesData)

  const onCatChange = (...theArgs) => {
    const unit = theArgs[0]
    const selectedCategories = theArgs[1].map(cat => cat.id)
    const result = { ...unit, category: selectedCategories }

    onCategoryChange(result)
  }

  return (
    <Table variant="news">
      <Table.Head>
        <tr>
          <Table.HeadCell>№</Table.HeadCell>
          <Table.HeadCell>
            Дата публикации <br /> (источник)
          </Table.HeadCell>
          <Table.HeadCell>
            Дата публикации <br /> (сайт)
          </Table.HeadCell>
          <Table.HeadCell>Новость</Table.HeadCell>
          <Table.HeadCell>Источник</Table.HeadCell>
          <Table.HeadCell>Категория</Table.HeadCell>
          <Table.HeadCell>Регион</Table.HeadCell>
          <Table.HeadCell>Статус</Table.HeadCell>
          <Table.HeadCell>Действие</Table.HeadCell>
        </tr>
      </Table.Head>

      <Table.Body>
        {news.map((unit, idx) => (
          <Table.Row key={unit.id}>
            <Table.Cell className="table-news-id">{unit.id}</Table.Cell>
            <Table.Cell className="table-news-date">
              {getDateTimeFromTimestamp(unit.date)}
            </Table.Cell>
            <Table.Cell className="table-news-time">
              {unit.save_date ? getDateTimeFromTimestamp(unit.save_date) : '-'}
            </Table.Cell>
            <Table.Cell className="table-news-title">
              <a
                href={unit.url}
                className="text-[#2180CE] underline hover:no-underline"
                target="_blank"
              >
                {unit.title}
              </a>
            </Table.Cell>
            <Table.Cell className="table-news-source" data-title="Источник">
              {getSourceNameById(sources, unit.source)}
            </Table.Cell>
            <Table.Cell className="table-news-category" data-title="Категории">
              <CategoryTools unit={unit} onChange={onCatChange} />
            </Table.Cell>
            <Table.Cell className="table-news-category" data-title="Категории">
              {unit.region || '-'}
            </Table.Cell>
            <Table.Cell className="table-news-status" data-title="Статус">
              {NEWS_STATUSES_NAMES[unit.status]}
            </Table.Cell>
            <Table.Cell className="table-news-actions">
              <div className="flex items-center justify-center">
                <NewsActions status={unit.status} unit={unit} onEdit={onEdit} />
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}
