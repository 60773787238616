import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

import { RoleBasedRedirect } from 'ui/molecules'

import { selectAuthStatus, selectUserRole } from 'redux/selectors/authSelectors'

export const PublicRoutes = () => {
  const isAuthenticated = useSelector(selectAuthStatus)
  const role = useSelector(selectUserRole)

  if (isAuthenticated) {
    return <RoleBasedRedirect role={role} />
  }

  return <Outlet />
}
