import ReactPaginate from 'react-paginate'

export const Pagination = ({ total, size, initialPage, onPageClick }) => (
  <ReactPaginate
    className="pagination is-end"
    breakLabel="..."
    nextLabel="Следующая"
    initialPage={initialPage}
    disableInitialCallback={true}
    onPageChange={onPageClick}
    marginPagesDisplayed={2}
    pageRangeDisplayed={2}
    pageCount={Math.ceil(total / size)}
    previousLabel="Предыдущая"
    renderOnZeroPageCount={null}
  />
)
