import { configureStore } from '@reduxjs/toolkit'

import authReducer from './slices/authSlice'
import autopubReducer from './slices/autopubSlice'
import bannersReducer from './slices/bannersSlice'
import categoriesReducer from './slices/categoriesSlice'
import newsReducer from './slices/newsSlice'
import regionsReducer from './slices/regionsSlice'
import sourcesReducer from './slices/sourcesSlice'
import sourceRegionsReducer from './slices/sourceRegionsSlice'
import statisticsReducer from './slices/statisticsSlice'
import stopwordsReducer from './slices/stopwordsSlice'
import usersReducer from './slices/usersSlice'
import widgetReducer from './slices/widgetsSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    autopub: autopubReducer,
    banners: bannersReducer,
    categories: categoriesReducer,
    news: newsReducer,
    regions: regionsReducer,
    sources: sourcesReducer,
    sourceRegions: sourceRegionsReducer,
    statistics: statisticsReducer,
    stopwords: stopwordsReducer,
    users: usersReducer,
    widgets: widgetReducer,
  },
})
