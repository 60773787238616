import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import cn from 'classnames'

import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

import { Field, Input, Label, ErrorMessage, Checkbox, Radio } from 'ui/atoms'
import { MaskedInput } from 'ui/molecules'

const MESSAGES = {
  REQUIRED: 'Укажите значение',
  POSITIVE: 'Значение должно быть положительным',
  TYPE_ERROR: 'Значение должно быть числом',
  REGIONAL: 'Укажите % региональных новостей',
  FEDERAL: 'Укажите % федеральных новостей',
  BALTIC_BELARUS: 'Укажите % новостей Прибалтики и Баларуси',
  EUROPE: 'Укажите % новостей Европы',
  WORLD: 'Укажите % мировых новостей',
}

const schema = yup.object({
  region_proportion_on: yup.boolean(),
  regional: yup.number().when('region_proportion_on', {
    is: true,
    then: yup
      .number()
      .required(MESSAGES.REGIONAL)
      .positive(MESSAGES.POSITIVE)
      .typeError(MESSAGES.REGIONAL),
  }),
  federal: yup.number().when('region_proportion_on', {
    is: true,
    then: yup
      .number()
      .required(MESSAGES.FEDERAL)
      .positive(MESSAGES.POSITIVE)
      .typeError(MESSAGES.FEDERAL),
  }),
  baltic_belarus: yup.number().when('region_proportion_on', {
    is: true,
    then: yup
      .number()
      .required(MESSAGES.BALTIC_BELARUS)
      .positive(MESSAGES.POSITIVE)
      .typeError(MESSAGES.BALTIC_BELARUS),
  }),
  europe: yup.number().when('region_proportion_on', {
    is: true,
    then: yup
      .number()
      .required(MESSAGES.EUROPE)
      .positive(MESSAGES.POSITIVE)
      .typeError(MESSAGES.EUROPE),
  }),
  world: yup.number().when('region_proportion_on', {
    is: true,
    then: yup
      .number()
      .required(MESSAGES.WORLD)
      .positive(MESSAGES.POSITIVE)
      .typeError(MESSAGES.WORLD),
  }),
})

export const FeedForm = ({ defaultValues, onSubmit }) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    shouldUnregister: true,
  })
  const isRegionsEnabled = watch('region_proportion_on')

  return (
    <form className="max-w-sm" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center mb-6">
        <label
          className="text-[#f14668] font-bold"
          htmlFor="region_proportion_on"
        >
          Соотношение по регионам
        </label>
        <Tippy content="Если опция отключена, то лента новостей будет отсортирована по дате публикации новостей на сайте">
          <input
            id="region_proportion_on"
            className="ml-2"
            type="checkbox"
            {...register('region_proportion_on')}
          />
        </Tippy>
      </div>

      {isRegionsEnabled && (
        <>
          <Field className="flex flex-wrap justify-between">
            <Label className="shrink-0">Региональные новости</Label>

            <Controller
              name="regional"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <MaskedInput
                  className="w-24"
                  mask={'0[0]%'}
                  value={value.toString()}
                  unmask={true}
                  inputRef={ref}
                  lazy={false}
                  onAccept={onChange}
                />
              )}
            />

            {errors.regional && (
              <ErrorMessage
                className="w-full text-right"
                message={errors.regional.message}
              />
            )}
          </Field>

          <Field className="flex flex-wrap justify-between">
            <Label className="shrink-0">Федеральные новости</Label>

            <Controller
              name="federal"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <MaskedInput
                  className="w-24"
                  mask={'0[0]%'}
                  value={value.toString()}
                  unmask={true}
                  inputRef={ref}
                  lazy={false}
                  onAccept={onChange}
                />
              )}
            />

            {errors.federal && (
              <ErrorMessage
                className="w-full text-right"
                message={errors.federal.message}
              />
            )}
          </Field>

          <Field className="flex flex-wrap justify-between">
            <Label className="shrink-0">Прибалтика и Беларусь</Label>

            <Controller
              name="baltic_belarus"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <MaskedInput
                  className="w-24"
                  mask={'0[0]%'}
                  value={value.toString()}
                  unmask={true}
                  inputRef={ref}
                  lazy={false}
                  onAccept={onChange}
                />
              )}
            />

            {errors.baltic_belarus && (
              <ErrorMessage
                className="w-full text-right"
                message={errors.baltic_belarus.message}
              />
            )}
          </Field>

          <Field className="flex flex-wrap justify-between">
            <Label>Европа</Label>

            <Controller
              name="europe"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <MaskedInput
                  className="w-24"
                  mask={'0[0]%'}
                  value={value.toString()}
                  unmask={true}
                  inputRef={ref}
                  lazy={false}
                  onAccept={onChange}
                />
              )}
            />

            {errors.europe && (
              <ErrorMessage
                className="w-full text-right"
                message={errors.europe.message}
              />
            )}
          </Field>

          <Field className="flex flex-wrap justify-between mb-12">
            <Label>Мир</Label>

            <Controller
              name="world"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <MaskedInput
                  className="w-24"
                  mask={'0[0]%'}
                  value={value.toString()}
                  unmask={true}
                  inputRef={ref}
                  lazy={false}
                  onAccept={onChange}
                />
              )}
            />

            {errors.world && (
              <ErrorMessage
                className="w-full text-right"
                message={errors.world.message}
              />
            )}
          </Field>
        </>
      )}

      <Field className="mb-4">
        <Checkbox
          title='Отображать блок "Калининградское время"'
          reversed
          {...register('display_kaliningrad_time')}
        />
      </Field>

      <Field className="mb-12">
        <Checkbox
          title="Отображать тег KD"
          reversed
          {...register('display_tag_kd')}
        />
      </Field>

      <p className="font-bold mb-2">Отображать ленту новостей по умолчанию</p>

      <Radio
        title='"Все" + блок "Калинградское время"'
        value="true"
        reversed
        {...register('display_news_feed_default')}
      />

      <Radio
        title='Фильтр "Калининград"'
        value="false"
        reversed
        {...register('display_news_feed_default')}
      />

      <div className="text-right pt-8">
        <button
          className={cn('text-white py-2 px-4 rounded-md font-semibold', {
            'bg-green-500': isDirty,
            'bg-green-300 cursor-not-allowed': !isDirty,
          })}
          type="submit"
          disabled={!isDirty}
        >
          Сохранить
        </button>
      </div>
    </form>
  )
}
