import React, { useEffect } from 'react'
import Select from 'react-select'
import * as R from 'ramda'

import { useSourceRegionsManager } from 'hooks/useSourceRegionsManager'

export const SourceRegionsSelect = React.forwardRef(
  ({ onChange, name, value }, ref) => {
    const { sourceRegions, loadSourceRegions } = useSourceRegionsManager()

    useEffect(() => {
      loadSourceRegions()
    }, [])

    const onRegionChange = data => onChange(data.id)

    return (
      <Select
        ref={ref}
        name={name}
        value={sourceRegions.filter(i => i.id === value)}
        options={sourceRegions}
        getOptionLabel={R.prop('name')}
        getOptionValue={R.prop('id')}
        onChange={onRegionChange}
      />
    )
  }
)
