import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import api from 'api/api'
import { BASE_ERROR_MESSAGE } from 'constants/messages'

export const fetchCategories = createAsyncThunk(
  'categories/fetch',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.categories.fetch()

      return response
        ? response.data.categories
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const addCategory = createAsyncThunk(
  'categories/add',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.categories.add(payload)

      return response ? response.data : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const editCategory = createAsyncThunk(
  'categories/edit',
  async (payload, { rejectWithValue }) => {
    try {
      const { id, ...params } = payload

      const response = await api.categories.edit({ id, params })

      return response && response.status === 204
        ? payload
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

export const deleteCategory = createAsyncThunk(
  'categories/delete',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.categories.delete(payload)

      return response.status === 204
        ? payload
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(
        error.response.data.description || BASE_ERROR_MESSAGE
      )
    }
  }
)

const initialState = {
  data: [],
  loading: false,
  error: false,
}

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  extraReducers: {
    [fetchCategories.pending]: state => {
      state.loading = true
    },
    [fetchCategories.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = false
    },
    [fetchCategories.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [addCategory.pending]: state => {
      state.loading = true
    },
    [addCategory.fulfilled]: (state, { payload }) => {
      state.data = [...state.data, payload]
      state.loading = false
      state.error = false
    },
    [addCategory.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [editCategory.pending]: state => {
      state.loading = true
    },
    [editCategory.fulfilled]: (state, { payload }) => {
      state.data = state.data.map(category =>
        category.id === payload.id ? payload : category
      )
      state.loading = false
      state.error = false
    },
    [editCategory.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [deleteCategory.pending]: state => {
      state.loading = true
    },
    [deleteCategory.fulfilled]: (state, { payload }) => {
      state.data = state.data.filter(item => item.id !== payload)
      state.loading = false
      state.error = false
    },
    [deleteCategory.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },
})

export default categoriesSlice.reducer
