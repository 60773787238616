export const NEWS_STATUSES = {
  ALL: 'all',
  AUTO: 'auto',
  CANCELED: 'canceled',
  PUBLISHED: 'published',
  MODERATE: 'moderate',
  REVOKED: 'revoked',
}

export const NEWS_STATUSES_NAMES = {
  all: 'Все статусы',
  auto: 'Автопубликация',
  canceled: 'Отклонено',
  published: 'Опубликовано',
  moderate: 'На проверке',
  revoked: 'Отозвано',
}
