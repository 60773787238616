import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import cn from 'classnames'

import { Checkbox, ErrorMessage, Field, Input, Label } from 'ui/atoms'
import { useEffect } from 'react'

import { FILE_SIZE, SUPPORTED_FORMATS } from 'constants/banners'

const schema = yup.object().shape(
  {
    isEditing: yup.boolean(),
    display_num: yup
      .number()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .typeError('Количество показов задается числом'),
    name: yup.string().required('Укажите имя баннера'),
    image: yup.mixed().when('isEditing', {
      is: false,
      then: yup
        .mixed()
        .test(
          'required',
          'Загрузите изображение баннера',
          value => value && value.length > 0
        )
        .test(
          'fileSize',
          'Файл слишком большой',
          value => value && value[0]?.size <= FILE_SIZE
        )
        .test(
          'fileFormat',
          'Неподдерживаемый формат изображения. Поддерживается только *.png',
          value => value && SUPPORTED_FORMATS.includes(value[0]?.type)
        ),
    }),
    priority: yup
      .number()
      .required('Укажите приоритет баннера')
      .positive('Приоритет не может быть меньше 1')
      .integer('Приоритет должен быть целым числом')
      .typeError('Приоритет задается числом'),
    display_time_sec: yup
      .number()
      .required('Укажите время отображения баннера')
      .positive('Время отображения должно быть положительным числом')
      .integer('Время отображения должно быть целым числом')
      .typeError('Время отображения задается числом'),
    url: yup.string().when('url', value => {
      return value
        ? yup.string().url('Введите валидный url')
        : yup.string().nullable().optional()
    }),
  },
  [['url', 'url']]
)

export const BannersForm = ({
  defaultValues,
  isEditing = false,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    setValue('isEditing', isEditing)
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-2xl width-full">
      <Field>
        <Label>Имя баннера</Label>
        <Input className="input" type="text" {...register('name')} />
        {errors.name && <ErrorMessage message={errors.name.message} />}
      </Field>

      <Field>
        <Label>Изображение баннера</Label>
        <p className="text-xs">Рекомендуемый размер (320px x 240px)</p>
        <Input className="input" type="file" {...register('image')} />
        {errors.image && <ErrorMessage message={errors.image.message} />}
      </Field>

      <Field>
        <Label>
          Количество раз отображения (если не указано, то бесконечность)
        </Label>
        <Input className="input" type="text" {...register('display_num')} />
        {errors.display_num && (
          <ErrorMessage message={errors.display_num.message} />
        )}
      </Field>

      <Field>
        <Label>Приоритет</Label>
        <Input className="input" type="text" {...register('priority')} />
        {errors.priority && <ErrorMessage message={errors.priority.message} />}
      </Field>

      <Field>
        <Label>Время отображения (в секундах)</Label>
        <Input
          className="input"
          type="text"
          {...register('display_time_sec')}
        />
        {errors.display_time_sec && (
          <ErrorMessage message={errors.display_time_sec.message} />
        )}
      </Field>

      <Field>
        <Label>URL</Label>
        <Input className="input" type="text" {...register('url')} />
        {errors.url && <ErrorMessage message={errors.url.message} />}
      </Field>

      <Field className="mb-12">
        <Checkbox title="Активный" {...register('active')} />
      </Field>

      <div className="flex items-center justify-center">
        <button
          className={cn('text-white mr-2 py-2 px-4 rounded-md font-semibold', {
            'bg-green-500': !isLoading,
            'bg-green-300 cursor-not-allowed': isLoading,
          })}
          type="submit"
          disabled={isLoading}
        >
          Сохранить
        </button>
        <button
          className="bg-red-100 text-red-500 py-2 px-4 rounded-md font-semibold"
          type="button"
          onClick={onCancel}
        >
          Отмена
        </button>
      </div>
    </form>
  )
}
