import { Table } from 'ui/molecules'

export const CategoriesTable = ({ categories, onDelete, onEdit }) => {
  return (
    <Table variant="responsive">
      <Table.Head>
        <tr>
          <Table.HeadCell>№</Table.HeadCell>
          <Table.HeadCell>Имя категории</Table.HeadCell>
          <Table.HeadCell>Автопубликация</Table.HeadCell>
          <Table.HeadCell>Статус</Table.HeadCell>
          <Table.HeadCell>Категории источников</Table.HeadCell>
          <Table.HeadCell>Действие</Table.HeadCell>
        </tr>
      </Table.Head>
      <Table.Body>
        {categories.map((cat, idx) => (
          <Table.Row key={cat.id}>
            <Table.Cell className="is-hidden-mobile">{idx + 1}</Table.Cell>
            <Table.Cell className="text-lg lg:text-xs">{cat.name}</Table.Cell>
            <Table.Cell className="has-title" data-title="Автопубликация:">
              {cat.autopub ? 'Да' : 'Нет'}
            </Table.Cell>
            <Table.Cell className="has-title" data-title="Статус:">
              {cat.active ? 'Активна' : 'Неактивна'}
            </Table.Cell>
            <Table.Cell
              className="has-title is-block"
              data-title="Категории источников:"
            >
              {cat.src_categories && cat.src_categories.length ? (
                <p className="min-w-0">{cat.src_categories.join(', ')}</p>
              ) : (
                '-'
              )}
            </Table.Cell>
            <Table.Cell>
              <div className="flex items-center justify-center">
                <button
                  className="grow md:grow-0 bg-green-500 text-white mr-2 py-2 px-4 rounded-md font-semibold"
                  onClick={onEdit.bind(null, cat)}
                >
                  Изменить
                </button>
                <button
                  className="grow md:grow-0 bg-red-100 text-red-500 py-2 px-4 rounded-md font-semibold"
                  onClick={onDelete.bind(null, cat)}
                >
                  Удалить
                </button>
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}
