import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AdjustmentsIcon, XIcon } from '@heroicons/react/outline'
import cn from 'classnames'

import {
  CategoriesFilterSelect,
  SourcesSelect,
  StatusSelect,
} from 'ui/molecules'

import {
  setCategoriesFilter,
  setSourcesFilter,
  setStatusFilter,
} from 'redux/slices/newsSlice'
import {
  selectNewsCategoriesFilter,
  selectNewsSourcesFilter,
  selectNewsStatusFilter,
} from 'redux/selectors/newsSelectors'
import { useNewsSearch } from 'hooks/useNewsSearch'

export const NewsFilters = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const categoriesFilter = useSelector(selectNewsCategoriesFilter)
  const sourcesFilter = useSelector(selectNewsSourcesFilter)
  const statusFilter = useSelector(selectNewsStatusFilter)
  const dispatch = useDispatch()

  const onCategoriesFilterChange = data => dispatch(setCategoriesFilter(data))

  const onSourcesFilterChange = data => dispatch(setSourcesFilter(data))

  const onStatusFilterChange = data => dispatch(setStatusFilter(data.value))

  const panelClass = cn(
    'fixed lg:static top-0 left-0 bottom-0 z-10',
    'flex flex-col lg:flex-row',
    'w-full max-w-xs lg:max-w-none',
    'bg-[#F8F8F9]',
    'lg:translate-x-0 transition-transform lg:transition-none',
    {
      'translate-x-0': isPanelOpen,
      '-translate-x-full': !isPanelOpen,
    }
  )

  return (
    <div className="relative z-10 bg-neutral-100 lg:py-4">
      <div className="container mx-auto">
        <button
          className="lg:hidden flex items-center justify-center w-12 h-8 rounded-lg bg-white"
          onClick={() => setIsPanelOpen(true)}
        >
          <AdjustmentsIcon className="w-5 h-5" />
        </button>

        <div className={panelClass}>
          <header className="flex lg:hidden items-center justify-between mb-6 py-3 px-4 bg-white text-balck">
            <p className="text-lg font-medium">Выберите фильтры</p>

            <button
              className="flex items-center justify-center w-12 h-10"
              onClick={() => setIsPanelOpen(false)}
            >
              <XIcon className="w-6 h-6" />
            </button>
          </header>

          <div className="mb-4 lg:mb-0 lg:mr-2 px-4 lg:px-0">
            <CategoriesFilterSelect
              defaultValues={categoriesFilter}
              onChange={onCategoriesFilterChange}
            />
          </div>

          <div className="mb-4 lg:mb-0 lg:mr-2 px-4 lg:px-0">
            <SourcesSelect
              defaultValues={sourcesFilter}
              onChange={onSourcesFilterChange}
            />
          </div>

          <div className="mb-4 lg:mb-0 lg:ml-auto px-4 lg:px-0">
            <StatusSelect
              defaultValues={statusFilter}
              onChange={onStatusFilterChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
