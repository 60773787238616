import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { SourcesForm } from 'ui/organisms'
import { LayoutBase } from 'ui/layouts'

import { PATHS } from 'routing/paths'
import { editSource } from 'redux/slices/sourcesSlice'

export const SourcesEditPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    state: { source },
  } = useLocation()

  const onSourcesFormSubmit = async data => {
    try {
      const response = await dispatch(editSource(data)).unwrap()

      navigate(PATHS.SOURCES)
    } catch (error) {
      alert(error)
    }
  }

  const onSourcesFormCancel = () => navigate(PATHS.SOURCES)

  return (
    <LayoutBase>
      <div className="container  mx-auto pt-6 pb-12 px-4">
        <h1 className="mb-6 text-[32px] font-semibold">
          Редактировать источник
        </h1>

        <SourcesForm
          defaultValues={source}
          onSubmit={onSourcesFormSubmit}
          onCancel={onSourcesFormCancel}
        />
      </div>
    </LayoutBase>
  )
}
