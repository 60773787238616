import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Preloader } from 'ui/molecules'
import { WidgetsForm } from 'ui/organisms'
import { LayoutBase } from 'ui/layouts'

import { fetchWidgets, saveWidgets } from 'redux/slices/widgetsSlice'
import {
  selectWidgetsData,
  selectWidgetsLoadingStatus,
} from 'redux/selectors/widgetsSelectors'

export const WidgetsPage = () => {
  const widgets = useSelector(selectWidgetsData)
  const isLoading = useSelector(selectWidgetsLoadingStatus)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchWidgets())
  }, [])

  const onWidgetsFormSubmit = async data => {
    try {
      const response = await dispatch(saveWidgets(data)).unwrap()
    } catch (error) {
      alert(error)
    }
  }

  return (
    <LayoutBase>
      <div className="container mx-auto  mx-auto pt-6 pb-12 px-4">
        <h1 className="mb-6 text-[32px] font-semibold">Виджеты</h1>

        {isLoading ? (
          <Preloader />
        ) : (
          <WidgetsForm defaultValues={widgets} onSubmit={onWidgetsFormSubmit} />
        )}
      </div>
    </LayoutBase>
  )
}
