export const PATHS = {
  HOME: '/',
  FEED: '/feed',
  LOGIN: '/login',
  NEWS: '/news',
  SOURCES: '/sources',
  SOURCES_ADD: '/sources/add',
  SOURCES_EDIT: '/sources/edit',
  CATEGORIES: '/categories',
  CATEGORIES_ADD: '/categories/add',
  CATEGORIES_EDIT: '/categories/edit',
  BANNERS: '/banners',
  BANNERS_WIDGET: '/banners/widget',
  BANNERS_WIDGET_ADD: '/banners/widget/add',
  BANNERS_WIDGET_EDIT: '/banners/widget/edit',
  BANNERS_TOP: '/banners/top',
  BANNERS_TOP_ADD: '/banners/top/add',
  BANNERS_TOP_EDIT: '/banners/top/edit',
  USERS: '/users',
  USERS_ADD: '/users/add',
  USERS_EDIT: '/users/edit',
  AUTOPUB: '/autopub',
  WIDGETS: '/widgets',
  STATISTICS: '/statistics',
  STATISTICS_ADD: '/statistics/add',
  STATISTICS_EDIT: '/statistics/edit',
  STOPWORDS: '/stopwords',
  STOPWORDS_ADD: '/stopwords/add',
  STOPWORDS_EDIT: '/stopwords/edit',
}
