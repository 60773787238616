import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import * as R from 'ramda'

import { CategoriesForm } from 'ui/organisms'
import { LayoutBase } from 'ui/layouts'

import { PATHS } from 'routing/paths'
import { editCategory } from 'redux/slices/categoriesSlice'

export const CategoriesEditPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    state: { category },
  } = useLocation()

  const onCategoriesFormCancel = () => navigate(PATHS.CATEGORIES)

  const onCategoriesFormSubmit = async data => {
    const preparedData = R.omit(['src_categories'], data)

    try {
      const response = await dispatch(editCategory(preparedData)).unwrap()

      navigate(PATHS.CATEGORIES)
    } catch (error) {
      alert(error)
    }
  }

  return (
    <LayoutBase>
      <div className="container mx-auto pt-6 pb-12 px-4">
        <h1 className="mb-6 text-[32px] font-semibold">
          Редактировать категорию
        </h1>

        <CategoriesForm
          defaultValues={category}
          onCancel={onCategoriesFormCancel}
          onSubmit={onCategoriesFormSubmit}
        />
      </div>
    </LayoutBase>
  )
}
