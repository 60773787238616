import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Preloader, NewsLengthSelect } from 'ui/molecules'
import { NewsTable, NewsFilters, Pagination, Pager } from 'ui/organisms'
import { LayoutBase } from 'ui/layouts'

import { fetchSources } from 'redux/slices/sourcesSlice'
import { fetchCategories } from 'redux/slices/categoriesSlice'
import {
  fetchNews,
  editNewsUnit,
  editNewsUnitCategories,
} from 'redux/slices/newsSlice'
import {
  selectNewsData,
  selectNewsPaging,
  selectNewsLoadingState,
  selectNewsCategoriesFilter,
  selectNewsSourcesFilter,
  selectNewsStatusFilter,
} from 'redux/selectors/newsSelectors'
import { normalizeStatusFilter } from 'utils/status'
import { useNewsSearch } from 'hooks/useNewsSearch'

export const NewsPage = () => {
  const [selectedPage, setSelectedPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const news = useSelector(selectNewsData)
  const paging = useSelector(selectNewsPaging)
  const isLoading = useSelector(selectNewsLoadingState)
  const categoriesFilter = useSelector(selectNewsCategoriesFilter)
  const sourcesFilter = useSelector(selectNewsSourcesFilter)
  const statusFilter = useSelector(selectNewsStatusFilter)
  const dispatch = useDispatch()
  const { query, isSearching } = useNewsSearch()

  useEffect(() => {
    dispatch(fetchSources())
    dispatch(fetchCategories())
  }, [])

  useEffect(() => {
    setSelectedPage(1)

    dispatch(
      fetchNews({
        page_num: 1,
        page_size: pageSize,
        status: normalizeStatusFilter(statusFilter),
        category: categoriesFilter.join(','),
        source: sourcesFilter.join(','),
        title: query,
      })
    )
  }, [categoriesFilter, sourcesFilter, statusFilter, pageSize, query])

  const onNewsEdit = async data => {
    try {
      const response = await dispatch(editNewsUnit(data)).unwrap()
    } catch (error) {
      alert(error)
    }
  }

  const onNewsUnitCategoryChange = data =>
    dispatch(editNewsUnitCategories(data))

  const onPageClick = ({ selected }) => {
    const result = selected + 1

    setSelectedPage(result)
    dispatch(
      fetchNews({
        page_num: result,
        page_size: pageSize,
        status: normalizeStatusFilter(statusFilter),
        category: categoriesFilter.join(','),
        source: sourcesFilter.join(','),
      })
    )
  }

  const onPagerChange = page => {
    setSelectedPage(page)
    dispatch(
      fetchNews({
        page_num: page,
        page_size: pageSize,
        status: normalizeStatusFilter(statusFilter),
        category: categoriesFilter.join(','),
        source: sourcesFilter.join(','),
      })
    )
  }

  // const onNewsLengthChange = data => setPageSize(data)

  const newsPageContent = () => {
    if (isLoading) {
      return <Preloader />
    }

    if (!isLoading && !news.length) {
      return <p className="text-center font-semibold">Новостей нет</p>
    }

    return (
      <>
        <div className="mb-12 grow">
          <NewsTable
            news={news}
            onEdit={onNewsEdit}
            onCategoryChange={onNewsUnitCategoryChange}
          />
        </div>
        <div className="flex flex-col-reverse lg:flex-row items-end lg:items-center justify-end lg:justify-start">
          <NewsLengthSelect
            defaultValue={pageSize}
            onChange={setPageSize}
            className="mt-4 lg:mt-0"
          />

          <Pager
            className="ml-auto mr-6"
            total={paging.total}
            size={paging.size}
            onPageClick={onPagerChange}
            initialPage={selectedPage}
          />

          <Pagination
            total={paging.total}
            size={paging.size}
            onPageClick={onPageClick}
            initialPage={selectedPage - 1}
          />
        </div>
      </>
    )
  }

  return (
    <LayoutBase>
      {!isSearching && <NewsFilters />}
      <div className="flex flex-col container mx-auto pt-6 pb-12 px-4 bg-neutral-100 lg:bg-transparent">
        {newsPageContent()}
      </div>
    </LayoutBase>
  )
}
