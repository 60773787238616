import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import cn from 'classnames'

import { ErrorMessage, Field, Input, Label } from 'ui/atoms'
import { useEffect } from 'react'

import { FILE_SIZE, SUPPORTED_FORMATS } from 'constants/banners'

const schema = yup.object().shape(
  {
    isEditing: yup.boolean(),
    image_web: yup.mixed().when('isEditing', {
      is: false,
      then: yup
        .mixed()
        .test(
          'required',
          'Загрузите изображение баннера для web-версии',
          value => value && value.length > 0
        )
        .test(
          'fileSize',
          'Файл слишком большой',
          value => value && value[0]?.size <= FILE_SIZE
        )
        .test(
          'fileFormat',
          'Неподдерживаемый формат изображения. Поддерживается только *.png',
          value => value && SUPPORTED_FORMATS.includes(value[0]?.type)
        ),
    }),
    image_mobile: yup.mixed().when('isEditing', {
      is: false,
      then: yup
        .mixed()
        .test(
          'required',
          'Загрузите изображение баннера для мобильной версии',
          value => value && value.length > 0
        )
        .test(
          'fileSize',
          'Файл слишком большой',
          value => value && value[0]?.size <= FILE_SIZE
        )
        .test(
          'fileFormat',
          'Неподдерживаемый формат изображения. Поддерживается только *.png',
          value => value && SUPPORTED_FORMATS.includes(value[0]?.type)
        ),
    }),
    url: yup.string().when('url', value => {
      return value
        ? yup.string().url('Введите валидный url')
        : yup.string().nullable().optional()
    }),
  },
  [['url', 'url']]
)

export const BannersTopForm = ({
  defaultValues,
  isEditing = false,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  useEffect(() => {
    setValue('isEditing', isEditing)
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-2xl width-full">
      <Field>
        <Label>Изображение баннера для web-версии</Label>
        <p className="text-xs">Рекомендуемый размер (856px x 152px)</p>
        <Input className="input" type="file" {...register('image_web')} />
        {errors.image_web && (
          <ErrorMessage message={errors.image_web.message} />
        )}
      </Field>

      <Field>
        <Label>Изображение баннера для мобильной версии</Label>
        <p className="text-xs">Рекомендуемый размер (343px х 78px)</p>
        <Input className="input" type="file" {...register('image_mobile')} />
        {errors.image_mobile && (
          <ErrorMessage message={errors.image_mobile.message} />
        )}
      </Field>

      <Field>
        <Label>URL</Label>
        <Input className="input" type="text" {...register('url')} />
        {errors.url && <ErrorMessage message={errors.url.message} />}
      </Field>

      <div className="flex items-center justify-center">
        <button
          className={cn('text-white mr-2 py-2 px-4 rounded-md font-semibold', {
            'bg-green-500': !isLoading,
            'bg-green-300 cursor-not-allowed': isLoading,
          })}
          type="submit"
          disabled={isLoading}
        >
          Сохранить
        </button>
        <button
          className="bg-red-100 text-red-500 py-2 px-4 rounded-md font-semibold"
          type="button"
          onClick={onCancel}
        >
          Отмена
        </button>
      </div>
    </form>
  )
}
