import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { Preloader } from 'ui/molecules'
import { BannersTable, BannersPager } from 'ui/organisms'

import { PATHS } from 'routing/paths'
import { fetchBanners, deleteBanner } from 'redux/slices/bannersSlice'
import {
  selectBannersLoadingState,
  selectBannersData,
} from 'redux/selectors/bannersSelectors'

export const BannerWidget = () => {
  const banners = useSelector(selectBannersData)
  const isLoading = useSelector(selectBannersLoadingState)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchBanners())
  }, [])

  const onBannerDelete = banner => {
    let isConfirmed = window.confirm(
      `Вы уверены, что хотите удалить баннер ${banner.name}?`
    )

    if (isConfirmed) {
      dispatch(deleteBanner(banner.id))
    }

    return null
  }

  const onBannerEdit = banner =>
    navigate(PATHS.BANNERS_WIDGET_EDIT, { state: { banner } })

  return (
    <Fragment>
      <header className="mb-10">
        <h1 className="mb-6 text-[32px] font-semibold">Реклама</h1>

        {/* <BannersPager className="mb-8" /> */}

        <Link
          to={PATHS.BANNERS_WIDGET_ADD}
          className="inline-block bg-green-500 text-white py-2 px-4 rounded-md font-semibold"
        >
          Добавить баннер
        </Link>
      </header>

      <BannersTable
        banners={banners}
        isLoading={isLoading}
        onDelete={onBannerDelete}
        onEdit={onBannerEdit}
      />
    </Fragment>
  )
}
