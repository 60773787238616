import { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { BannersForm } from 'ui/organisms'

import { PATHS } from 'routing/paths'
import { editBanner } from 'redux/slices/bannersSlice'
import { buildFormData } from 'utils/form'

export const BannerWidgetEdit = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()

  const onBannersFormCancel = () => navigate(PATHS.BANNERS_WIDGET)

  const onBannersFormSubmit = async data => {
    try {
      const { id } = data
      const formData = buildFormData(data)

      await dispatch(editBanner({ id, data: formData })).unwrap()

      navigate(PATHS.BANNERS_WIDGET)
    } catch (error) {
      alert(error)
    }
  }

  if (!state) {
    return <Navigate to={PATHS.BANNERS_WIDGET} />
  }

  return (
    <Fragment>
      <h1 className="mb-6 text-[32px] font-semibold">Редактировать баннер</h1>

      <BannersForm
        defaultValues={{
          ...state.banner,
          display_num: state.banner.display_num || '',
        }}
        isEditing={true}
        onSubmit={onBannersFormSubmit}
        onCancel={onBannersFormCancel}
      />
    </Fragment>
  )
}
