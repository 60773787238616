import * as R from 'ramda'

export const selectRegionsLoadingState = R.path(['regions', 'loading'])

export const selectRegionsData = R.path(['regions', 'data'])

export const selectRegionsEnabledState = R.path(['regions', 'isEnabled'])

export const selectRegionsFullData = R.compose(
  R.mergeAll,
  R.paths([
    ['regions', 'data'],
    ['regions', 'settings'],
  ])
)
