import { useState, useEffect } from 'react'
import { SourcesSelect, StatUnitSelect, StatDeepFilter } from 'ui/molecules'

import { useStatisticsManager } from 'hooks/useStatisticsManager'

export const StatisticsFilters = () => {
  const [source, setSource] = useState('')
  const [unit, setUnit] = useState({ label: 'Дни', value: 'day' })
  const [deep, setDeep] = useState(1)
  const { loadStatistics } = useStatisticsManager()

  useEffect(() => {
    loadStatistics({ source, unit: unit.value, deep })
  }, [source, unit, deep])

  const onSourcesFilterChange = sources => {
    const result = sources.join(',')

    setSource(result)
  }

  return (
    <div className="bg-neutral-100 lg:py-4">
      <div className="container mx-auto sm:flex flex-wrap">
        <div className="mr-6 mb-4">
          <p className="mb-1 text-xs font-bold">Количество дней/часов/минут:</p>
          <StatDeepFilter onChange={setDeep} />
        </div>

        <div className="mr-6 mb-4">
          <p className="mb-1 text-xs font-bold">Период:</p>
          <StatUnitSelect defaultValue={unit} onChange={setUnit} />
        </div>

        <div>
          <p className="mb-1 text-xs font-bold">Источник:</p>
          <SourcesSelect onChange={onSourcesFilterChange} />
        </div>
      </div>
    </div>
  )
}
