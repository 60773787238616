import { Navigate } from 'react-router-dom'

import { ROLES } from 'constants/roles'
import { PATHS } from 'routing/paths'

export const RoleBasedRedirect = ({ role }) => {
  switch (role) {
    case ROLES.ADMIN:
      return <Navigate to={PATHS.USERS} />
    case ROLES.MODERATOR:
      return <Navigate to={PATHS.NEWS} />
    default:
      return null
  }
}
