import { Table, ImagePreview } from 'ui/molecules'

export const BannersTable = ({ banners, isLoading, onEdit, onDelete }) => {
  if (isLoading) {
    return <p className="text-center font-semibold">Загрузка...</p>
  }

  if (!isLoading && !banners.length) {
    return <p className="text-center font-semibold">Баннеров еще нет.</p>
  }

  return (
    <Table variant="responsive">
      <Table.Head>
        <tr>
          <Table.HeadCell>№</Table.HeadCell>
          <Table.HeadCell>Имя баннера</Table.HeadCell>
          <Table.HeadCell>Изображение баннера</Table.HeadCell>
          <Table.HeadCell>Количество раз отображения</Table.HeadCell>
          <Table.HeadCell>Приоритет</Table.HeadCell>
          <Table.HeadCell>Время отображения(сек.)</Table.HeadCell>
          <Table.HeadCell>Статус</Table.HeadCell>
          <Table.HeadCell>URL</Table.HeadCell>
          <Table.HeadCell>Действие</Table.HeadCell>
        </tr>
      </Table.Head>

      <Table.Body>
        {banners.map(banner => (
          <Table.Row key={banner.id}>
            <Table.Cell className="is-hidden-mobile">{banner.id}</Table.Cell>
            <Table.Cell>{banner.name}</Table.Cell>
            <Table.Cell>
              <ImagePreview id={banner.id} />
            </Table.Cell>
            <Table.Cell
              className="has-title"
              data-title="Количество раз отображения:"
            >
              {banner.display_num ? (
                banner.display_num
              ) : (
                <span className="text-base">&infin;</span>
              )}
            </Table.Cell>
            <Table.Cell className="has-title" data-title="Приоритет:">
              {banner.priority}
            </Table.Cell>
            <Table.Cell className="has-title" data-title="Время отображения:">
              {banner.display_time_sec}
            </Table.Cell>
            <Table.Cell className="has-title" data-title="Статус:">
              {banner.active ? 'Активный' : 'Неактивный'}
            </Table.Cell>
            <Table.Cell className="has-title" data-title="URL:">
              {banner.url ? banner.url : '-'}
            </Table.Cell>
            <Table.Cell>
              <div className="flex items-center justify-center">
                <button
                  className="grow md:grow-0 bg-green-500 text-white mr-2 py-2 px-4 rounded-md font-semibold"
                  onClick={onEdit.bind(null, banner)}
                >
                  Изменить
                </button>
                <button
                  className="grow md:grow-0 bg-red-100 text-red-500 py-2 px-4 rounded-md font-semibold"
                  onClick={onDelete.bind(null, banner)}
                >
                  Удалить
                </button>
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}
