import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as R from 'ramda'

import api from 'api/api'
import { BASE_ERROR_MESSAGE } from 'constants/messages'
import { normalizeRegionNewsSettingsProps } from 'utils/regions'

export const fetchRegions = createAsyncThunk(
  'regions/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.regions.fetch()

      return response
        ? R.mergeAll(response.data.regions)
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(error || BASE_ERROR_MESSAGE)
    }
  }
)

export const editRegions = createAsyncThunk(
  'regions/edit',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.regions.edit(payload)

      return response && response.status === 204
        ? payload
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(error || BASE_ERROR_MESSAGE)
    }
  }
)

export const fetchRegionsStatus = createAsyncThunk(
  'regions/status/fetch',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.regions.fetchStatus()

      return response ? response.data : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(error || BASE_ERROR_MESSAGE)
    }
  }
)

export const editRegionsStatus = createAsyncThunk(
  'regions/status/edit',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.regions.editStatus(payload)

      return response && response.status === 204
        ? payload
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(error || BASE_ERROR_MESSAGE)
    }
  }
)

export const fetchRegionNewsSettings = createAsyncThunk(
  'regions/news_settings/fetch',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.regions.fetchNewsSettings(payload)

      return response
        ? normalizeRegionNewsSettingsProps(response.data)
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(error || BASE_ERROR_MESSAGE)
    }
  }
)

export const editRegionNewsSettings = createAsyncThunk(
  'regions/news_settings/edit',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.regions.editNewsSettings(payload)

      return response && response.status === 204
        ? payload
        : rejectWithValue(BASE_ERROR_MESSAGE)
    } catch (error) {
      return rejectWithValue(error || BASE_ERROR_MESSAGE)
    }
  }
)

const initialState = {
  data: {},
  settings: {},
  isEnabled: true,
  loading: false,
  error: null,
}

export const regionsSlice = createSlice({
  name: 'regions',
  initialState,
  extraReducers: {
    [fetchRegions.pending]: state => {
      state.loading = true
    },
    [fetchRegions.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = false
      state.error = null
    },
    [fetchRegions.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [editRegions.pending]: state => {
      state.loading = true
    },
    [editRegions.fulfilled]: (state, { payload }) => {
      state.data = payload
      state.loading = false
      state.error = null
    },
    [editRegions.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [fetchRegionsStatus.pending]: state => {
      state.loading = true
    },
    [fetchRegionsStatus.fulfilled]: (state, { payload }) => {
      state.isEnabled = payload
      state.loading = false
      state.error = null
    },
    [fetchRegionsStatus.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [editRegionsStatus.pending]: state => {
      state.loading = true
    },
    [editRegionsStatus.fulfilled]: (state, { payload }) => {
      state.isEnabled = payload
      state.loading = false
      state.error = null
    },
    [editRegionsStatus.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
    [fetchRegionNewsSettings.pending]: state => {
      state.loading = true
    },
    [fetchRegionNewsSettings.fulfilled]: (state, { payload }) => {
      state.settings = payload
      state.loading = false
      state.erorr = null
    },
    [fetchRegionNewsSettings.rejected]: (state, { payload }) => {
      state.loading = false
      state.erorr = payload
    },
    [editRegionNewsSettings.pending]: state => {
      state.loading = true
    },
    [editRegionNewsSettings.fulfilled]: (state, { payload }) => {
      state.settings = payload
      state.loading = false
      state.erorr = null
    },
    [editRegionNewsSettings.rejected]: (state, { payload }) => {
      state.loading = false
      state.erorr = payload
    },
  },
})

export default regionsSlice.reducer
