import { useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useMatch } from 'react-router-dom'
import { LogoutIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import cn from 'classnames'

import { Search } from 'ui/organisms'

import { PATHS } from 'routing/paths'
import { ROLES } from 'constants/roles'
import { selectUserRole } from 'redux/selectors/authSelectors'
import { logout } from 'redux/slices/authSlice'

const MODER_ROUTES = [
  { title: 'Новости', path: PATHS.NEWS },
  { title: 'Автопубликация', path: PATHS.AUTOPUB },
  { title: 'Источники', path: PATHS.SOURCES },
  { title: 'Категории', path: PATHS.CATEGORIES },
  { title: 'Лента новостей', path: PATHS.FEED },
  { title: 'Виджеты', path: PATHS.WIDGETS },
  { title: 'Реклама', path: PATHS.BANNERS },
  { title: 'Стопслова', path: PATHS.STOPWORDS },
  { title: 'Статистика', path: PATHS.STATISTICS },
]

const ADMIN_ROUTES = [{ title: 'Пользователи', path: PATHS.USERS }]

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const role = useSelector(selectUserRole)
  const dispatch = useDispatch()

  const isAdmin = useMemo(() => role === ROLES.ADMIN, [role])
  const isModer = useMemo(() => role === ROLES.MODERATOR, [role])

  const isNewsPage = useMatch(PATHS.NEWS)

  const onLogoutClick = () => dispatch(logout())

  const navLinkClass = ({ isActive }) =>
    cn('block px-6 lg:px-3 py-4', {
      'bg-[#e8e8e8]': isActive,
    })

  const renderModerRoutes = () =>
    MODER_ROUTES.map(route => (
      <li key={route.title}>
        <NavLink to={route.path} className={navLinkClass}>
          {route.title}
        </NavLink>
      </li>
    ))

  const renderAdminRoutes = () =>
    ADMIN_ROUTES.map(route => (
      <li key={route.title}>
        <NavLink to={route.path} className={navLinkClass}>
          {route.title}
        </NavLink>
      </li>
    ))

  const panelClass = cn(
    'fixed lg:static top-0 left-0 bottom-0 z-10',
    'flex flex-col lg:flex-row lg:justify-between',
    'w-full max-w-xs lg:max-w-none',
    'bg-[#F8F8F9]',
    'lg:translate-x-0 transition-transform lg:transition-none',
    { 'translate-x-0': isMenuOpen, '-translate-x-full': !isMenuOpen }
  )

  return (
    <header className="bg-neutral-100">
      <div className="container mx-auto flex items-center justify-between py-4 lg:py-0">
        <button
          className="lg:hidden ml-auto"
          onClick={() => setIsMenuOpen(true)}
        >
          <MenuIcon className="w-6 h-6" />
        </button>

        <div className={panelClass}>
          <button
            className="flex lg:hidden items-center justify-center self-end w-12 h-10"
            onClick={() => setIsMenuOpen(false)}
          >
            <XIcon className="w-6 h-6" />
          </button>

          <ul className="flex flex-col lg:flex-row text-sm font-medium">
            {isModer && renderModerRoutes()}
            {isAdmin && renderAdminRoutes()}
          </ul>

          <div className="flex flex-col lg:flex-row lg:items-center mt-auto lg:mt-0">
            {isModer && isNewsPage && <Search className="mr-3" />}
            <button
              className="flex items-center justify-center w-full lg:w-6 h-6 py-5 lg:py-0 bg-white lg:bg-transparent"
              onClick={onLogoutClick}
            >
              <LogoutIcon className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}
