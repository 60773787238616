import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'

import { Preloader } from 'ui/molecules'
import { SourcesTable } from 'ui/organisms'
import { LayoutBase } from 'ui/layouts'

import { PATHS } from 'routing/paths'
import { fetchSources, deleteSource } from 'redux/slices/sourcesSlice'
import {
  selectSourcesData,
  selectSourcesLoadingState,
} from 'redux/selectors/sourcesSelectors'

export const SourcesPage = () => {
  const sources = useSelector(selectSourcesData)
  const isLoading = useSelector(selectSourcesLoadingState)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(fetchSources())
  }, [])

  const onSourceEdit = source =>
    navigate(PATHS.SOURCES_EDIT, { state: { source } })

  const onSourceDelete = source => {
    let isConfirmed = window.confirm(
      `Вы уверены, что хотите удалить источник ${source.name}?`
    )

    if (isConfirmed) {
      dispatch(deleteSource(source.id))
    }

    return null
  }

  return (
    <LayoutBase>
      <div className="container  mx-auto pt-6 pb-12 px-4 bg-neutral-100 lg:bg-transparent">
        <header className="mb-10">
          <h1 className="mb-6 text-[32px] font-semibold">Источники</h1>
          <Link
            to={PATHS.SOURCES_ADD}
            className="bg-green-500 text-white py-2 px-4 rounded-md font-semibold"
          >
            Добавить источник
          </Link>
        </header>

        {isLoading ? (
          <Preloader />
        ) : (
          <SourcesTable
            sources={sources}
            onDelete={onSourceDelete}
            onEdit={onSourceEdit}
          />
        )}
      </div>
    </LayoutBase>
  )
}
